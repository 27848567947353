/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace LegalConditionActions {
  export class Init {
    static readonly type = '[LegalCondition] Init'
  }
}
