/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { DynamicInputControlModel, DynamicInputControlModelConfig } from '../dynamic-input-control.model'
import { DynamicFormControlLayout } from '../misc/dynamic-form-control-layout.model'
import { maskToString } from '../../utils/json.utils'
import { isBoolean, isNumber } from '../../utils/core.utils'
import { isObservable, Observable, of } from 'rxjs'
import { tap } from 'rxjs/operators'

export const DYNAMIC_FORM_CONTROL_TYPE_INPUT = 'INPUT'

export const DYNAMIC_FORM_CONTROL_INPUT_TYPE_COLOR = 'color'
export const DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATE = 'date'
export const DYNAMIC_FORM_CONTROL_INPUT_TYPE_DATETIME_LOCAL = 'datetime-local'
export const DYNAMIC_FORM_CONTROL_INPUT_TYPE_EMAIL = 'email'
export const DYNAMIC_FORM_CONTROL_INPUT_TYPE_FILE = 'file'
export const DYNAMIC_FORM_CONTROL_INPUT_TYPE_MONTH = 'month'
export const DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER = 'number'
export const DYNAMIC_FORM_CONTROL_INPUT_TYPE_PASSWORD = 'password'
export const DYNAMIC_FORM_CONTROL_INPUT_TYPE_RANGE = 'range'
export const DYNAMIC_FORM_CONTROL_INPUT_TYPE_SEARCH = 'search'
export const DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEL = 'tel'
export const DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT = 'text'
export const DYNAMIC_FORM_CONTROL_INPUT_TYPE_TIME = 'time'
export const DYNAMIC_FORM_CONTROL_INPUT_TYPE_URL = 'url'
export const DYNAMIC_FORM_CONTROL_INPUT_TYPE_WEEK = 'week'

export interface DynamicInputModelConfig extends DynamicInputControlModelConfig<string | number | Date | string[]> {
  accept?: string
  inputType?: string
  displayStrengthMeter?: boolean
  list?: any[] | Observable<any[]>
  mask?: string | RegExp | typeof maskToString | (string | RegExp)[]
  max?: number | string | Date
  min?: number | string | Date
  multiple?: boolean
  pattern?: string
  step?: number
}

export class DynamicInputModel extends DynamicInputControlModel<string | number | Date | string[]> {
  accept: string | null
  inputType: string
  displayStrengthMeter: boolean
  files: FileList | null = null
  list$: Observable<any[]> | null = null
  mask: string | RegExp | typeof maskToString | (string | RegExp)[] | null
  max: number | string | Date | null
  min: number | string | Date | null
  multiple: boolean | null
  pattern: string | null
  step: number | null

  private _list: any[] | null = null
  private readonly _listId: string | null = null

  readonly type: string = DYNAMIC_FORM_CONTROL_TYPE_INPUT

  constructor(config: DynamicInputModelConfig, layout?: DynamicFormControlLayout) {
    super(config, layout)

    this.accept = config.accept || null
    this.inputType = config.inputType || DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT
    this.mask = config.mask || null
    this.displayStrengthMeter = config.displayStrengthMeter
    this.max = config.max !== undefined ? config.max : null
    this.min = config.min !== undefined ? config.min : null
    this.multiple = isBoolean(config.multiple) ? config.multiple : null
    this.pattern = config.pattern || null
    this.step = isNumber(config.step) ? config.step : null

    if (config.list !== undefined) {
      this.list = config.list
      this._listId = `${this.id}List`
    }
  }

  get listId(): string | null {
    return this._listId
  }

  get hasList(): boolean {
    return isObservable(this.list$)
  }

  set list(list: any[] | Observable<any[]> | null) {
    if (Array.isArray(list)) {
      this._list = list
      this.list$ = of(this._list)
    } else if (isObservable(list)) {
      this.list$ = list.pipe(tap((list) => (this._list = list)))
    } else {
      this._list = null
      this.list$ = null
    }
  }
}
