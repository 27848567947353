/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { AbstractControl, ValidationErrors } from '@angular/forms'
import { parsePhoneNumber } from 'awesome-phonenumber'

// Regex taken from https://emailregex.com/
const EMAIL_REGEX = // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export function emailRfcValidator(control: AbstractControl): ValidationErrors | null {
  return EMAIL_REGEX.test(control.value) ? null : { emailRfc: true }
}

export function mobileValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (!control.value) {
    return null
  }
  const phoneNumber = parsePhoneNumber(control.value)

  if (!phoneNumber.valid) {
    return { mobile: true }
  }

  return null
}

export function containsStringValidator(toCheck: string) {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return toCheck && control.value?.toLowerCase().indexOf(toCheck.toLowerCase()) !== -1 ? { containsString: true } : null
  }
}

export function containsLowerCaseValidator(control: AbstractControl): { [key: string]: any } | null {
  return control.value && !/.*[a-z].*/.test(control.value) ? { lowercase: true } : null
}

export function containsUpperCaseValidator(control: AbstractControl): { [key: string]: any } | null {
  return control.value && !/.*[A-Z].*/.test(control.value) ? { uppercase: true } : null
}

export function containsNumbersValidator(control: AbstractControl): { [key: string]: any } | null {
  return control.value && !/\d/.test(control.value) ? { numbers: true } : null
}

export function containsSpecialCharactersValidator(control: AbstractControl): { [key: string]: any } | null {
  return control.value && !/.*[^A-Za-z0-9].*/.test(control.value) ? { specialchars: true } : null
}
