<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
  -->

<div
  [formGroup]="group"
  [ngClass]="['ng-dynamic-forms-' + model.type.toLowerCase(), getClass('element', 'container'), getClass('grid', 'container')]"
>
  <ng-container #componentViewContainer></ng-container>

  <ng-content></ng-content>
</div>
