<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
  -->

<dynamic-form-control
  *ngFor="let controlModel of model; trackBy: trackByFn"
  [group]="group"
  [hidden]="controlModel.hidden"
  [layout]="layout"
  [model]="controlModel"
  (blur)="onBlur($event)"
  (change)="onChange($event)"
  (focus)="onFocus($event)"
  (metaDataSelectedEvent)="onMetadataSelected($event)"
></dynamic-form-control>
