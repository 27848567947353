/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

export const environment = {
  production: false,
  cn_environment: true,
  features: {
    isRegisterWithMobileNumberOnly: true,
    pictureServiceEnabled: false
  },
  urls: {
    ui: 'https://signup.porsche-preview.cn',
    api: 'https://api.porsche-preview.com/',
    apiCN: 'https://api.porsche-preview.cn/',
    localisation: 'https://localise.profile.porsche.com/',
    localisationCN: 'https://localise.profile.porsche.cn/',
    shortLinkApi: 'https://shortlink-profile.porsche-preview.cn/',
    shortLinkBackupApi: 'https://shortlink-profile.porsche-preview.com/',
  },
  cookieConsent: {
    enable: false,
    usc: {
      useItpBridge: true,
      enableForLocalhost: false,
    },
  },
  oauth: {
    clientId: 'p9AVeef5rlZUaIdiVeZ9liyL6D7I3sDK',
    domain: 'identity.porsche-preview.com',
    authorizationParams: {
      redirect_uri: 'https://signup.porsche-preview.cn/auth/callback',
      audience: 'https://api.porsche-preview.com',
      scope: 'openid email profile',
    },
    httpInterceptor: {
      allowedList: [
        {
          uriMatcher: (uri) =>
            uri.match(
              RegExp('https://api.porsche-preview.cn/signup/registration/v[0-9]*/[a-z]{2}/[a-z]{2}_[A-Z]{2}/register/organisation'),
            ) !== null,
        },
        {
          uriMatcher: (uri) =>
            uri.match(
              RegExp('https://api.porsche-preview.cn/signup/registration/v[0-9]*/[a-z]{2}/[a-z]{2}_[A-Z]{2}/action/secure-resend'),
            ) !== null,
        },
        {
          uriMatcher: (uri) =>
            uri.match(RegExp('https://api.porsche-preview.cn/signup/confirmation/v[0-9]*/[a-z]{2}/[a-z]{2}_[A-Z]{2}/secure-auth')) !== null,
        },
        {
          uriMatcher: (uri) =>
            uri.match(RegExp('https://api.porsche-preview.cn/signup/confirmation/v[0-9]*/[a-z]{2}/[a-z]{2}_[A-Z]{2}/secure-confirm')) !==
            null,
        },
      ],
    },
  },
  apiKeyWhiteListUrls: ['https://api.porsche-preview.com', 'https://api.porsche-preview.cn'],
  navigationOptions: {
    environment: 'pp',
  },
  logger: {
    showLogs: true,
    showWarnings: true,
  },
  friendlyCaptchaSiteKey: 'FCMOB9H4C3GOIITP',
  googleTagManagerV2: {
    enable: false,
    containerId: '',
    skipInitialPageView: true,
    data: {
      context: {
        applicationId: '',
        environment: '',
      },
    },
  },
  externalUrls: {
    portalAddVehicle: 'https://my.porsche-preview.cn/core/:country/:locale/vehicles/add',
    personalData: 'https://profile.porsche-preview.cn/myprofile/:country/:locale/personal-data',
    myPorscheHome: 'https://my.porsche-preview.cn/core/:country/:locale/home',
    pictureService: 'https://signup.porsche-preview.cn/pictures'
  },
  fullstory: {
    devMode: false,
    orgId: 'ZXFPS',
  },
  mockServiceWorker: {
    enabled: false,
  },
  ngxs: {
    developmentMode: false,
    devToolsDisabled: true,
  },
  angularCommonAssets: 'https://signup.porsche-preview.cn/assets/static/angular-common/',
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'
