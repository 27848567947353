/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

export enum CookieConsentServiceName {
  AMAZON_CLOUDFRONT = 'AMAZON_CLOUDFRONT',
  AMAZON_WEB_SERVICES = 'AMAZON_WEB_SERVICES',
  AUTH0 = 'AUTH0',
  AWIN = 'AWIN',
  AZERION = 'AZERION',
  BOOTSTRAP_CDN = 'BOOTSTRAP_CDN',
  CHARGING_MAP = 'CHARGING_MAP',
  CLOUDFLARE = 'CLOUDFLARE',
  CLOUDFRONT_NET = 'CLOUDFRONT_NET',
  CONVERSION_LINKER = 'CONVERSION_LINKER',
  DOUBLECLICK_FLOODLIGHT = 'DOUBLECLICK_FLOODLIGHT',
  ESSENTIAL_FUNCTIONS = 'ESSENTIAL_FUNCTIONS',
  FACEBOOK_CUSTOM_AUDIENCES_PIXEL = 'FACEBOOK_CUSTOM_AUDIENCES_PIXEL',
  FACEBOOK_PIXEL = 'FACEBOOK_PIXEL',
  FACEBOOK_SOCIAL_PLUGINS = 'FACEBOOK_SOCIAL_PLUGINS',
  FACEBOOK_VIDEOS = 'FACEBOOK_VIDEOS',
  FULLSTORY = 'FULLSTORY',
  GOOGLE_ADS = 'GOOGLE_ADS',
  GOOGLE_ADSENSE = 'GOOGLE_ADSENSE',
  GOOGLE_ADWORDS_CONVERSION = 'GOOGLE_ADWORDS_CONVERSION',
  GOOGLE_ADWORDS_REMARKETING = 'GOOGLE_ADWORDS_REMARKETING',
  GOOGLE_AJAX = 'GOOGLE_AJAX',
  GOOGLE_ANALYTICS = 'GOOGLE_ANALYTICS',
  GOOGLE_ANALYTICS_AUDIENCES = 'GOOGLE_ANALYTICS_AUDIENCES',
  GOOGLE_MAPS = 'GOOGLE_MAPS',
  GOOGLE_MARKETING_PLATFORM_FLOODLIGHT = 'GOOGLE_MARKETING_PLATFORM_FLOODLIGHT',
  GOOGLE_OPTIMIZE = 'GOOGLE_OPTIMIZE',
  GOOGLE_TAG_MANAGER = 'GOOGLE_TAG_MANAGER',
  INSTAGRAM_CONTENT = 'INSTAGRAM_CONTENT',
  LINKEDIN = 'LINKEDIN',
  LINKEDIN_ADS = 'LINKEDIN_ADS',
  LINKEDIN_INSIGHT_TAG = 'LINKEDIN_INSIGHT_TAG',
  MATOMO = 'MATOMO',
  NEW_RELIC = 'NEW_RELIC',
  NR_DATA_NET = 'NR_DATA_NET',
  PORSCHE_CAR_CONFIGURATOR = 'PORSCHE_CAR_CONFIGURATOR',
  PORSCHE_SELECTED_DEALER = 'PORSCHE_SELECTED_DEALER',
  PSYMA = 'PSYMA',
  QUALTRICS = 'QUALTRICS',
  RECAPTCHA = 'RECAPTCHA',
  REFINE = 'REFINE',
  STORYSTREAM = 'STORYSTREAM',
  TEADS_ADVERTISER = 'TEADS_ADVERTISER',
  TEALIUM_AUDIENCE_STREAM = 'TEALIUM_AUDIENCE_STREAM',
  TRADEDESK = 'TRADEDESK',
  TWITTER_ADVERTISING = 'TWITTER_ADVERTISING',
  TWITTER_PLUGIN = 'TWITTER_PLUGIN',
  USERCENTRICS_CONSENT_MANAGEMENT_PLATFORM = 'USERCENTRICS_CONSENT_MANAGEMENT_PLATFORM',
  USERCENTRICS_CONSENT_MANAGEMENT_PLATFORM_BRIDGE = 'USERCENTRICS_CONSENT_MANAGEMENT_PLATFORM_BRIDGE',
  USERLIKE = 'USERLIKE',
  VERIZON = 'VERIZON',
  VIMEO = 'VIMEO',
  YOUTUBE_VIDEO = 'YOUTUBE_VIDEO',
  ZEMATA = 'ZEMATA',
}

export const TECHNICAL_NAME_MAPPING: { [key in CookieConsentServiceName]: string } = {
  AMAZON_CLOUDFRONT: 'uc-amazoncloudfront',
  AMAZON_WEB_SERVICES: 'uc-amazonwebservices',
  AUTH0: 'uc-auth',
  AWIN: 'uc-awin',
  AZERION: 'uc-azerion',
  BOOTSTRAP_CDN: 'uc-bootstrapcdn',
  CHARGING_MAP: 'uc-chargingmap',
  CLOUDFLARE: 'uc-cloudflare',
  CLOUDFRONT_NET: 'uc-cloudfront',
  CONVERSION_LINKER: 'uc-conversionlinker',
  DOUBLECLICK_FLOODLIGHT: 'uc-doubleclickfloodlight',
  ESSENTIAL_FUNCTIONS: 'uc-essential',
  FACEBOOK_CUSTOM_AUDIENCES_PIXEL: 'uc-fbpixel',
  FACEBOOK_PIXEL: 'uc-facebookpixel',
  FACEBOOK_SOCIAL_PLUGINS: 'uc-facebooksocialplugins',
  FACEBOOK_VIDEOS: 'uc-facebookvideos',
  FULLSTORY: 'uc-fullstory',
  GOOGLE_ADS: 'uc-googleads',
  GOOGLE_ADSENSE: 'uc-googleadsense',
  GOOGLE_ADWORDS_CONVERSION: 'uc-googleadwordsconversion',
  GOOGLE_ADWORDS_REMARKETING: 'uc-googleadwordsremarketing',
  GOOGLE_AJAX: 'uc-googleajax',
  GOOGLE_ANALYTICS: 'uc-googleanalytics',
  GOOGLE_ANALYTICS_AUDIENCES: 'uc-googleanalyticsaudiences',
  GOOGLE_MAPS: 'uc-googlemaps',
  GOOGLE_MARKETING_PLATFORM_FLOODLIGHT: 'uc-floodlight',
  GOOGLE_OPTIMIZE: 'uc-googleoptimize',
  GOOGLE_TAG_MANAGER: 'uc-googletagmanager',
  INSTAGRAM_CONTENT: 'uc-instagramcontent',
  LINKEDIN: 'uc-linkedin',
  LINKEDIN_ADS: 'uc-linkedinads',
  LINKEDIN_INSIGHT_TAG: 'uc-linkedininsighttag',
  MATOMO: 'uc-matomocookieless',
  NEW_RELIC: 'uc-newrelic',
  NR_DATA_NET: 'uc-nrdata',
  PORSCHE_CAR_CONFIGURATOR: 'uc-icc',
  PORSCHE_SELECTED_DEALER: 'uc-selecteddealer',
  PSYMA: 'uc-psyma',
  QUALTRICS: 'uc-qualtrics',
  RECAPTCHA: 'uc-recaptcha',
  REFINE: 'uc-refine',
  STORYSTREAM: 'uc-storystream',
  TEADS_ADVERTISER: 'uc-teadsadvertiser',
  TEALIUM_AUDIENCE_STREAM: 'uc-tealium',
  TRADEDESK: 'uc-tradedesk',
  TWITTER_ADVERTISING: 'uc-twitteradvertising',
  TWITTER_PLUGIN: 'uc-twitterplugin',
  USERCENTRICS_CONSENT_MANAGEMENT_PLATFORM: 'uc-cmp',
  USERCENTRICS_CONSENT_MANAGEMENT_PLATFORM_BRIDGE: 'uc-itp',
  USERLIKE: 'uc-userlike',
  VERIZON: 'uc-verizon',
  VIMEO: 'uc-vimeo',
  YOUTUBE_VIDEO: 'uc-youtubevideo',
  ZEMATA: 'uc-zemata',
}

export const SERVICENAME_BY_TECHNICAL_NAME = Object.assign(
  {},
  ...Object.entries(TECHNICAL_NAME_MAPPING).map(([serviceName, technicalName]) => ({ [technicalName]: serviceName })),
)
