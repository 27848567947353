/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { signupServiceHandlers } from './signup-service/signup-service.handler'
import { shortLinkServiceHandlers } from './short-link-service/short-link-service.handler'

export const handlers = [...signupServiceHandlers, ...shortLinkServiceHandlers]
