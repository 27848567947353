/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { DynamicFormControlModel, DynamicFormControlModelConfig } from '../dynamic-form-control.model'
import { DynamicFormControlLayout } from '../misc/dynamic-form-control-layout.model'

export const DYNAMIC_FORM_CONTROL_TYPE_PARAGRAPH = 'PARAGRAPH'

export class DynamicParagraphModel extends DynamicFormControlModel {
  readonly type: string = DYNAMIC_FORM_CONTROL_TYPE_PARAGRAPH

  constructor(config: DynamicFormControlModelConfig, layout?: DynamicFormControlLayout) {
    super(config, layout)
  }
}
