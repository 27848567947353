/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

export enum TransactionType {
  PROSPECT = 'PROSPECT',
  TRACK_YOUR_DREAM = 'TRACK_YOUR_DREAM',
  NON_CONNECT_OWNER = 'NON_CONNECT_OWNER',
  CONNECT_OWNER = 'CONNECT_OWNER',
  ADD_NON_CONNECT_VEHICLE = 'ADD_NON_CONNECT_VEHICLE',
  ADD_CONNECT_VEHICLE = 'ADD_CONNECT_VEHICLE',
  SECONDARY = 'SECONDARY',
  SECONDARY_VEHICLE = 'SECONDARY_VEHICLE',
  SIMPLIFIED_ADD_CONNECT_VEHICLE = 'SIMPLIFIED_ADD_CONNECT_VEHICLE',
  SIMPLIFIED_ADD_NON_CONNECT_VEHICLE = 'SIMPLIFIED_ADD_NON_CONNECT_VEHICLE',
}
