<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
  -->

<div class="flex mt-static_s">
  <div *ngFor="let strengthItem of strengthItems" [ngClass]="strengthItem" class="strength__item mr-static_xs"></div>
</div>

<p-text size="small" class="inline-block mt-static_xs mr-static_xs">{{
  'signup.password.strength.indicator.headlineLabel' | translate
}}</p-text>
<ng-container [ngSwitch]="passwordStrength?.id">
  <p-text tag="span" size="small" class="ml-static_xs inline-block" *ngSwitchDefault>{{
    'signup.password.strength.indicator.weakLabel' | translate
  }}</p-text>
  <p-text tag="span" size="small" class="ml-static_xs inline-block" *ngSwitchCase="2">{{
    'signup.password.strength.indicator.mediumLabel' | translate
  }}</p-text>
  <p-text tag="span" size="small" class="ml-static_xs inline-block" *ngSwitchCase="3">{{
    'signup.password.strength.indicator.strongLabel' | translate
  }}</p-text>
</ng-container>

<div *ngIf="passwordCriteriaVisible">
  <p-text class="mt-static_m"><span [innerHTML]="'signup.password.strength.criteria.label' | translate"></span></p-text>
  <p-text-list>
    <p-text-list-item class="mt-static_s"
      >{{ 'signup.password.strength.criteria.hasMinLengthLabel' | translate }}
      <p-icon *ngIf="hasMinLength" color="notification-success" name="check" aria-label="Check icon"></p-icon>
      <p-icon *ngIf="!hasMinLength" color="notification-error" name="close" aria-label="Check icon"></p-icon
    ></p-text-list-item>
    <p-text-list-item class="mt-static_s"
      >{{ 'signup.password.strength.criteria.containsNumbersLabel' | translate }}
      <p-icon *ngIf="containsNumbers" color="notification-success" name="check" aria-label="Check icon"></p-icon>
      <p-icon *ngIf="!containsNumbers" color="notification-error" name="close" aria-label="Check icon"></p-icon
    ></p-text-list-item>
    <p-text-list-item class="mt-static_s"
      >{{ 'signup.password.strength.criteria.containsLowercaseAndUppercaseLabel' | translate }}
      <p-icon *ngIf="containsLowercaseAndUppercase" color="notification-success" name="check" aria-label="Check icon"></p-icon>
      <p-icon *ngIf="!containsLowercaseAndUppercase" color="notification-error" name="close" aria-label="Check icon"></p-icon
    ></p-text-list-item>
    <p-text-list-item class="mt-static_s"
      >{{ 'signup.password.strength.criteria.containsSymbolsLabel' | translate }}
      <p-icon *ngIf="containsSymbols" color="notification-success" name="check" aria-label="Check icon"></p-icon>
      <p-icon *ngIf="!containsSymbols" color="notification-error" name="close" aria-label="Check icon"></p-icon
    ></p-text-list-item>
  </p-text-list>
</div>
