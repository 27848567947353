/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component, ElementRef, Inject, Input, ViewChild } from '@angular/core'
import { TransactionType } from '../../enums/transaction-type'
import { Select, Store } from '@ngxs/store'
import { ConfirmationSelectors } from '../../state/confirmation/confirmation.selectors'
import { Observable } from 'rxjs'
import { SignupService } from '../../services/signup/signup.service'
import { UntypedFormGroup } from '@angular/forms'
import { PidxGoogleTagManagerIntegrationService } from '../../services/google-tag-manager/pidx-google-tag-manager-integration.service'
import { Confirmation } from '../../state/confirmation/confirmation.actions'
import { LegalCondition } from '../../models/legal-conditions'
import { FormValidationService } from '../../services/form/form-validation.service'
import { ConfirmationStep } from '../../state/confirmation-step.enum'
import { ConfirmationCodeStatus } from '../../enums/confirmation-code-status'
import { WINDOW } from '../../services/utils'
import SetSPIN = Confirmation.SetSPIN
import { filter } from 'rxjs/operators'
import { PinCodeState, PinCodeType, PinCodeUpdateEvent } from '@porsche-design-system/components-angular'
import { setPinCodeFocus } from '../../services/utils'

@Component({
    selector: 'myprofile-signup-ui-set-spin',
    templateUrl: './set-spin.component.html',
    standalone: false
})
export class SetSpinComponent {
  @Input() currentPageName: string
  @Input() legalConditions: LegalCondition[]
  @Input() steps: any[]
  @Input() currentStep: ConfirmationStep

  @ViewChild('spinCode', { read: ElementRef }) spinCodeComponent!: ElementRef
  @ViewChild('spinRepeatCode', { read: ElementRef }) spinRepeatCodeComponent!: ElementRef

  @Select(ConfirmationSelectors.currentStep) public readonly currentStep$: Observable<ConfirmationStep>
  @Select(ConfirmationSelectors.transactionType) public readonly transactionType$: Observable<TransactionType>
  @Select(ConfirmationSelectors.spinRequested) public readonly spinRequested$: Observable<boolean>

  public formGroup = new UntypedFormGroup({})
  public readonly spinLength = 4
  public spinCodeType: PinCodeType = 'password'
  public spinCodeState: PinCodeState = 'none'
  public spinCodeRepeatState: PinCodeState = 'none'
  public spinCodeMessage = ''
  public disableSubmitButtonAndShowLoading = false

  private _spinValue: string
  private _spinRepeatValue: string
  public spinCodeStatus = ConfirmationCodeStatus.CODE_STATUS_DEFAULT
  public spinRepeatCodeStatus = ConfirmationCodeStatus.CODE_STATUS_DEFAULT

  constructor(
    @Inject(WINDOW) private _window: Window,
    private _tagManagerIntegrationService: PidxGoogleTagManagerIntegrationService,
    private readonly store: Store,
    private _signupService: SignupService,
  ) {
    this.currentStep$.pipe(filter((step) => step === ConfirmationStep.SET_SPIN)).subscribe(() => {
      // from a ux perspective it makes sense to auto-focus the first input
      this._window.setTimeout(() => {
        setPinCodeFocus(this.spinCodeComponent.nativeElement.shadowRoot)
      }, 100)
    })
  }

  get lastStepLabel() {
    switch (this._signupService.transactionData.type) {
      default:
      case 'OWNER':
        return 'signup.lastStep.owner.button.label'
      case 'VEHICLE':
        return 'signup.lastStep.vehicle.button.label'
    }
  }

  public isLastStep(currentStep: ConfirmationStep) {
    const maxIndex = this.steps.length - 1
    const index = this.steps.indexOf(currentStep)

    return maxIndex === index
  }

  public submitSpinAndTrack(e: CustomEvent<PinCodeUpdateEvent>) {
    if (!e.detail.isComplete && this._spinValue?.length != this.spinLength) return
    this.checkSPINValidity(e.detail.value.trim())
    if (this.spinCodeStatus === ConfirmationCodeStatus.CODE_STATUS_VALID) {
      if (this._spinRepeatValue?.length === this.spinLength) {
        this.checkSPINRepeatValidity(this._spinRepeatValue)
        this._tagManagerIntegrationService.triggerLoadTracking({
          pageName: 'sign_up/setspin',
          eventAction: 'PAGMyPorsche_SignUpSetsPin_Load',
        })
        // remove the focus of the last input
        this.spinCodeComponent.nativeElement.blur()
      } else {
        // from a ux perspective it makes sense to auto-focus the next input
        setPinCodeFocus(this.spinRepeatCodeComponent.nativeElement.shadowRoot)
      }
    }
  }

  public submitSpinRepeatAndTrack(e: CustomEvent<PinCodeUpdateEvent>) {
    if (!e.detail.isComplete && this._spinRepeatValue?.length != this.spinLength) return
    this.checkSPINRepeatValidity(e.detail.value.trim())
    if (this.spinRepeatCodeStatus === ConfirmationCodeStatus.CODE_STATUS_VALID) {
      this._tagManagerIntegrationService.triggerLoadTracking({
        pageName: 'sign_up/setspin',
        eventAction: 'PAGMyPorsche_SignUpSetsPin_Load',
      })
    }
  }

  private checkSPINValidity(spinValue: string) {
    this._spinValue = spinValue

    if (this._spinValue?.length === this.spinLength) {
      this.spinCodeStatus = ConfirmationCodeStatus.CODE_STATUS_VALID
      this.spinCodeState = 'success'
    } else {
      this.spinCodeStatus = ConfirmationCodeStatus.CODE_STATUS_INVALID
      this.spinCodeState = 'error'
    }
  }

  private checkSPINRepeatValidity(spinRepeatValue: string) {
    this._spinRepeatValue = spinRepeatValue

    if (
      this._spinValue === this._spinRepeatValue &&
      this._spinValue?.length === this.spinLength &&
      this._spinRepeatValue?.length === this.spinLength
    ) {
      this.spinRepeatCodeStatus = ConfirmationCodeStatus.CODE_STATUS_VALID
      this.spinCodeRepeatState = 'success'
      // remove the focus of the last input
      this.spinRepeatCodeComponent.nativeElement.blur()
      this.spinCodeMessage = ''
    } else {
      this.spinRepeatCodeStatus = ConfirmationCodeStatus.CODE_STATUS_INVALID
      this.spinCodeRepeatState = 'error'
      this.spinCodeMessage = 'signup.createSpinStep.errorMessages.mismatch'
    }
  }

  public async submitSpinForm(): Promise<void> {
    FormValidationService.triggerFormValidationErrors(this.formGroup)
    if (
      this._spinValue.length !== this.spinLength ||
      this._spinRepeatValue.length !== this.spinLength ||
      this._spinValue !== this._spinRepeatValue
    ) {
      this._tagManagerIntegrationService.trackFormError(this.formGroup, {
        pageName: 'sign_up/setspin',
        eventAction: 'PAGMyPorsche_SignUpSetSpinError_Load',
      })
      return
    }
    this.disableSubmitButtonAndShowLoading = true
    this.formGroup.disable({ emitEvent: false })

    this.store.dispatch(new SetSPIN(this._spinValue))
    this._tagManagerIntegrationService.triggerLoadTracking({
      pageName: 'sign_up/setspin',
      eventAction: 'PAGMyPorsche_SignUpSetsPin_Load',
    })
  }
}
