/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component, Inject } from '@angular/core'
import { SignupFooterLink } from '../../components/footer/footer.component'
import { ConfigService } from '../../services/config/config.service'
import { Router } from '@angular/router'
import { DOCUMENT } from '@angular/common'

@Component({
    selector: 'myprofile-signup-ui-layout-prospect',
    templateUrl: './layout-prospect.component.html',
    standalone: false
})
export class LayoutProspectComponent {
  private readonly _country: string

  constructor(private _configService: ConfigService, private _router: Router, @Inject(DOCUMENT) private _document: Document) {
    this._country = this._configService.getUrlCountry()
  }

  public get showFooterLinkChina(): boolean {
    return this._country === 'cn'
  }

  public get showFooterLinkUSA(): boolean {
    return this._country === 'us'
  }

  public get footerLinks(): SignupFooterLink[] {
    return this._configService.getFooterLinks().meta
  }

  public languageChanged(selectedLocale: string) {
    // This will get the url-path after the country/locales like 'success-page'
    const routeSegmentsWithoutCountryAndLocale = this._router.url.split('/').slice(3)
    this._document.location.href = [
      this._configService.getBaseUrl(),
      this._configService.getUrlCountry(),
      selectedLocale,
      ...routeSegmentsWithoutCountryAndLocale,
    ].join('/')
  }
}
