/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { API_KEY } from '../constants'
import { Observable } from 'rxjs'
import { ConfigService } from '../services/config/config.service'

@Injectable({
  providedIn: 'root',
})
export class ApiKeyInterceptor implements HttpInterceptor {
  constructor(@Inject(API_KEY) private _apiKey, private _configService: ConfigService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let urlMatches: boolean
    if (!Array.isArray(this._configService.apiKeyWhiteListUrls)) {
      urlMatches = req.url.startsWith(this._configService.apiKeyWhiteListUrls)
    } else {
      urlMatches = this._configService.apiKeyWhiteListUrls.some((url) => {
        if (typeof url === 'string') {
          return req.url.startsWith(url)
        }
        return url.test(req.url)
      })
    }
    if (urlMatches) {
      const requestClone = req.clone({
        setHeaders: {
          apikey: this._apiKey,
        },
      })
      return next.handle(requestClone)
    }
    return next.handle(req)
  }
}
