/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { Inject, Injectable } from '@angular/core'
import { AppConfig, FooterLinks, HeaderExternalLink, LocaleInformation } from './config.interface'
import { environment } from '../../../environments/environment'
import { HttpInterceptorConfig } from '@auth0/auth0-angular'
import { Observable, of, ReplaySubject, take } from 'rxjs'
import { WINDOW } from '../utils'
import { tap } from 'rxjs/operators'
import { ConfigLoaderService } from './config-loader.service'

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  protected config: AppConfig = {}
  protected country = ''
  protected locale = ''
  protected _initialized = new ReplaySubject<void>(1)
  initialized = this._initialized.asObservable().pipe(take(1))

  constructor(private configLoader: ConfigLoaderService, @Inject(WINDOW) private _window: Window) {}

  init(locale: string, country: string): Observable<AppConfig> {
    this.locale = locale
    this.country = country

    return this.loadConfig(locale, country).pipe(
      tap(() => {
        this._initialized.next()
      }),
    )
  }

  setUrlLocaleCountryInformation(locale: string, country: string) {
    this.country = country
    this.locale = locale
  }

  getUrlLocale(): string {
    return this.locale
  }

  getUrlCountry(): string {
    return this.country
  }

  getTranslationFileName(): string {
    return this.config?.localeInformation?.translationFileName
  }

  getFallbackTranslationFileName(): string {
    return this.config?.localeInformation?.fallbackTranslationFileName
  }

  getAngularLocale(): string {
    return this.config?.localeInformation?.angularLocale
  }

  getIso6391Language(): string {
    return this.config?.localeInformation?.iso6391Language
  }

  getLocaleInformation(): LocaleInformation {
    return this.config?.localeInformation
  }

  getInvalidLocaleRedirectUrl(): string {
    return this.config?.redirectInformation?.url
  }

  isCNDomain(): boolean {
    return this.getHostname().includes('.cn')
  }

  getHostname(): string {
    return this._window.location.hostname
  }

  getBaseUrl(): string {
    return environment.urls.ui
  }

  getBaseApiUrl(): string {
    return this.isCNDomain() ? environment.urls.apiCN : environment.urls.api
  }

  getBaseShortLinkApiUrl(): string {
    return environment.urls.shortLinkApi
  }

  getBaseShortLinkBackupApiUrl(): string {
    return environment.urls.shortLinkBackupApi
  }

  getExternalUrl(key: string, anchor: string = null): string {
    // eslint-disable-next-line no-prototype-builtins
    if (environment.externalUrls.hasOwnProperty(key)) {
      let externalUrl = environment.externalUrls[key].replace(':country', this.getUrlCountry()).replace(':locale', this.getUrlLocale())
      if (anchor) {
        externalUrl = `${externalUrl}-${anchor}`
      }
      return externalUrl
    }

    return null
  }

  getLocalisationBaseUrl(): string {
    return this.isCNDomain() ? environment.urls.localisationCN : environment.urls.localisation
  }

  get getTranslationsUrl(): string {
    const STAGE = environment.production ? 'RELEASE' : 'LATEST'
    return `${this.getLocalisationBaseUrl()}translations/${STAGE}/signup/`
  }

  getHeaderLinks(): HeaderExternalLink[] {
    return this.config?.NAVIGATION
  }

  get friendlyCaptchaSiteKey(): string {
    return environment.friendlyCaptchaSiteKey
  }

  get apiKeyWhiteListUrls(): string | (string | RegExp)[] {
    return environment.apiKeyWhiteListUrls
  }

  isProduction(): boolean {
    return environment.production
  }

  isPictureServiceEnabled(): boolean {
    return environment.features.pictureServiceEnabled
  }

  getGTMApplicationID(): string {
    return environment.googleTagManagerV2.data.context.applicationId
  }

  getGTMEnvironment(): string {
    return environment.googleTagManagerV2.data.context.environment
  }

  getGTMIsEnabled(): boolean {
    return environment.googleTagManagerV2.enable && !this.config.isTestAccount
  }

  getGTMContainerId(): string {
    return environment.googleTagManagerV2.containerId
  }

  getHttpInterceptorConfig(): HttpInterceptorConfig {
    return environment?.oauth?.httpInterceptor
  }

  getFooterLinks(): FooterLinks {
    return this.config?.footerLinks
  }

  private loadConfig(locale: string, country: string): Observable<AppConfig> {
    if (this.configLoader !== null) {
      return this.configLoader.getConfig(locale, country).pipe(
        tap((config: AppConfig) => {
          this.config = config
        }),
      )
    }

    return of(null)
  }
}
