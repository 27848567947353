<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
  -->
<myprofile-signup-ui-navigation></myprofile-signup-ui-navigation>
<main class="grid grid-cols-1 l:grid-cols-2 l:overflow-hidden h-full">
  <div class="hidden relative l:grid l:grid-cols-2 l:grid-rows-2 l:overflow-hidden">
    <div>
      <picture>
        <source [srcset]="'assets/images/prospect/register-prospect-top-left.webp'" [type]="'image/webp'" />
        <img class="w-full h-full object-cover block"
             [attr.src]="'assets/images/prospect/register-prospect-top-left.webp'"
             alt="A Porsche with illuminated brake lights" />
      </picture>
    </div>
    <div >
      <picture>
        <source [srcset]="'assets/images/prospect/register-prospect-top-right.webp'" [type]="'image/webp'" />
        <img class="w-full h-full object-cover block"
             [attr.src]="'assets/images/prospect/register-prospect-top-right.webp'"
             alt="A Porsche in an illuminated neighborhood" />
      </picture>
    </div>
    <div>
      <picture>
        <source [srcset]="'assets/images/prospect/register-prospect-bottom-left.webp'" [type]="'image/webp'" />
        <img class="w-full h-full object-cover block"
             [attr.src]="'assets/images/prospect/register-prospect-bottom-left.webp'"
             alt="A women sitting in a Porsche" />
      </picture>
    </div>
    <div>
      <picture>
        <source [srcset]="'assets/images/prospect/register-prospect-bottom-right.webp'" [type]="'image/webp'" />
        <img class="w-full h-full object-cover block"
             [attr.src]="'assets/images/prospect/register-prospect-bottom-right.webp'" alt="A Porsche silhouette" />
      </picture>
    </div>
      <div style="transform: translate(-50%, -50%);" class="top-1/2 left-1/2 min-w-[515px] absolute px-s rounded bg-black text-[clamp(20px,3vw,40px)]">
              <p-display align="center" size="inherit" theme="dark">Connect Moments That Matter</p-display>
      </div></div>
  <div class="l:overflow-y-auto flex flex-col">
    <div class="px-xl flex flex-1 pb-l">
      <!-- *ngIf="true" hack is required here for router-outlet to work correctly. Otherwise,
        it won't register the changes on the layout and won't update the view. -->
      <router-outlet *ngIf="true"></router-outlet>
    </div>
    <myprofile-signup-ui-footer class="mt-l"
                                [footerLinks]="footerLinks"
                                [showFooterLinksChina]="showFooterLinkChina"
                                [showFooterLinksUS]="showFooterLinkUSA"
    [languageChooserEnabled]="false"
    (languageChanged)="languageChanged($event)">
    </myprofile-signup-ui-footer>
  </div>
</main>
