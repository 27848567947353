/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { InjectionToken } from '@angular/core'
import { AbstractControl } from '@angular/forms'
import { DynamicFormControlModel } from '../model/dynamic-form-control.model'

export type DynamicErrorMessagesMatcher = (control: AbstractControl, model: DynamicFormControlModel, hasFocus: boolean) => boolean

export const DEFAULT_ERROR_STATE_MATCHER: DynamicErrorMessagesMatcher = (
  control: AbstractControl,
  model: DynamicFormControlModel,
  hasFocus: boolean,
) => {
  return control.touched && !hasFocus
}

export const DYNAMIC_ERROR_MESSAGES_MATCHER = new InjectionToken<DynamicErrorMessagesMatcher>('DYNAMIC_ERROR_MESSAGES_MATCHER')
