/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { LayoutDefaultComponent } from './layout-default/layout-default.component'
import { LayoutComponent } from './layout.component'
import { RouterModule } from '@angular/router'
import { ComponentsModule } from '../components/components.module'
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular'
import { LayoutProspectComponent } from './layout-prospect/layout-prospect.component'
import { LayoutSuccessComponent } from './layout-success/layout-success.component'
import { FooterComponent } from '../components/footer/footer.component'
import { NavigationComponent } from '../components/navigation/navigation.component'
import {MaintenanceComponent} from "../pages/maintenance/maintenance.component";
import {LayoutMaintenanceComponent} from "./layout-maintenance/layout-maintenance.component";

@NgModule({
  declarations: [LayoutDefaultComponent, LayoutProspectComponent, LayoutSuccessComponent, LayoutComponent],
  imports: [CommonModule, RouterModule, ComponentsModule, PorscheDesignSystemModule, FooterComponent, NavigationComponent, MaintenanceComponent, LayoutMaintenanceComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LayoutModule {}
