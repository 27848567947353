/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { http, HttpResponse } from 'msw'
import { BackendRoutes } from '../../../app/constants'
import {
  alreadyConfirmedShortLinkData,
  ownerMobileShortLinkData,
  ownerShortLinkData,
  prospectShortLinkData,
  simplifiedAddVehicleMobileShortLinkData,
  simplifiedAddVehicleShortLinkData,
  trackYourDreamShortLinkData,
  vehicleShortLinkData,
} from './data'

const getPath = (requestUrl: string) => `http://shortlink-base.local/${requestUrl}`

export const shortLinkServiceHandlers = [http.get(getPath(BackendRoutes.v1.SHORT_LINK__RESOLVE), resolveLink)]

const resolveShortLink = (shortLinkId) => {
  const shortLinkMap = {
    _prospect: prospectShortLinkData,
    _owner: ownerShortLinkData,
    _owner_mobile: ownerMobileShortLinkData,
    _vehicle: vehicleShortLinkData,
    _sav: simplifiedAddVehicleShortLinkData,
    _sav_mobile: simplifiedAddVehicleMobileShortLinkData,
    _tyd: trackYourDreamShortLinkData,
    _already_confirmed: alreadyConfirmedShortLinkData,
  }
  return shortLinkMap[shortLinkId]
}

function resolveLink({ request }) {
  const url = new URL(request.url)
  const shortLinkId = url.searchParams.get('shortLinkId')

  if (shortLinkId != null && shortLinkId === '_not_found') {
    return new HttpResponse(
      JSON.stringify({
        statusCode: 404,
        message: 'RESOURCE_NOT_FOUND_SHORTLINK',
      }),
      { status: 404 },
    )
  }

  const data = resolveShortLink(shortLinkId)

  if (!data) {
    return new HttpResponse(
      JSON.stringify({
        errorCode: 'INTERNAL_SERVER_ERROR',
        message: 'INTERNAL_SERVER_ERROR',
      }),
      { status: 500 },
    )
  }
  return HttpResponse.json(data)
}
