<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
  -->
<p-heading tag="h1" class="text-balance mt-static_l xs:mt-l">
  {{ 'signup.activateAccount.confirmIdentity.headline' | translate }}
</p-heading>

<myprofile-signup-ui-notification-banner
  class="absolute z-501"
  [isOpen]="openBanner"
  [state]="'error'"
  [message]="'signup.activateAccount.tooManySmsSentError.description'"
>
</myprofile-signup-ui-notification-banner>

<div class="w-full">
  <div *ngIf="transactionType$ | async as transactionType">
    <p-text size="{base: 'small', m: 'medium' }" class="mt-static_m s:mt-static_l text-balance" >
      <span
        *ngIf="
          transactionType === TransactionType.CONNECT_OWNER ||
          transactionType === TransactionType.NON_CONNECT_OWNER ||
          transactionType === TransactionType.SECONDARY ||
          transactionType === TransactionType.SECONDARY_VEHICLE
        "
        [innerHTML]="'signup.activateAccount.confirmationCode.intro.label' | translate: { MASKED_MOBILE_NO: mobile }"
      ></span>
      <span
        *ngIf="transactionType === TransactionType.ADD_CONNECT_VEHICLE || transactionType === TransactionType.ADD_NON_CONNECT_VEHICLE"
        [innerHTML]="'signup.confirmVehicle.confirmationCode.intro.label' | translate: { MASKED_MOBILE_NO: mobile }"
      ></span>
      <span
        *ngIf="
          transactionType === TransactionType.SIMPLIFIED_ADD_NON_CONNECT_VEHICLE ||
          transactionType === TransactionType.SIMPLIFIED_ADD_CONNECT_VEHICLE
        "
        [innerHTML]="'signup.simplifiedAddVehicle.confirmation.intro.label' | translate: { MASKED_MOBILE_NO: mobile }"
      ></span>
    </p-text>

    <p-text size="{base: 'small', m: 'medium' }" class="mt-static_l" *ngIf="
          transactionType === TransactionType.SIMPLIFIED_ADD_NON_CONNECT_VEHICLE ||
          transactionType === TransactionType.SIMPLIFIED_ADD_CONNECT_VEHICLE
        "
    >
      <span
        class="m:mr-static_xs"
        [innerText]="'signup.simplifiedAddVehicle.confirmation.incorrectNumber.text' | translate"
      ></span>
        <span class="m:mr-static_xs">
        <p-link-pure
          [ngClass]="{ 'underline-link': true }"
          data-e2e="simpliefied-add-vehicle-go-to-manual-process"
          [icon]="'none'"
          [myprofileSignupUiTrack]="{
            clickElementType: 'link',
            clickElementId: 'linkout',
            clickElementName: 'link_to_external-target',
            pageName: currentPageName,
            eventAction: 'PAGMyPorsche_SignUpIdentityRequest_Click'
          }"
          (click)="goToManualAddVehicleProcess()"
        >
          <a>
            {{ 'signup.simplifiedAddVehicle.confirmation.incorrectNumber.link.text' | translate }}
          </a>
        </p-link-pure>
      </span>
    </p-text>

    <div>
      <p-button
        (click)="onRequestOTP()"
        class="mt-static_l w-full xs:w-auto"
        variant="primary"
        data-e2e="confirmation-requestOTP-submit-button"
        [disabled]="(otpRequested$ | async) || (otpRequestedBlocked$ | async)"
        [loading]="otpRequested$ | async"
        [myprofileSignupUiTrack]="{
          clickElementType: 'button',
          clickElementId: 'call_to_action',
          clickElementName: 'request_confirmation_code',
          pageName: currentPageName,
          eventAction: 'PAGMyPorsche_SignUpIdentityRequest_Click'
        }"
      >
        {{ 'signup.activateAccount.confirmationCode.button.label' | translate }}
      </p-button>
    </div>

    <myprofile-signup-ui-cancel-activation></myprofile-signup-ui-cancel-activation>

    <p-text class="mt-static_l" *ngIf="isAddVehicleLeanActivation">
      <span [innerHTML]="leanActivationText"></span>
    </p-text>
  </div>
</div>
