<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
  -->

<div
  role="radiogroup"
  [formGroup]="group"
  [id]="id"
  [attr.data-name]="model.name"
  [ngClass]="[getClass('element', 'control'), getClass('grid', 'control')]"
  [tabindex]="model.tabIndex"
  (change)="onChange($event)"
>
  <p-radio-button-wrapper
    *ngFor="let option of model.options$ | async; let i = index"
    [ngClass]="getClass('element', 'option')"
    [label]="option.label"
    [hide-label]="false"
  >
    <input
      type="radio"
      [formControlName]="model.id"
      [name]="model.name"
      [value]="option.value"
      (blur)="onBlur($event)"
      (focus)="onFocus($event)"
      [id]="model.name + i"
    />
  </p-radio-button-wrapper>
</div>
