<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
  -->
<myprofile-signup-ui-notification-banner
  class="absolute z-501"
  [isOpen]="openBanner"
  [state]="'error'"
  [message]="'signup.activateAccount.tooManySmsSentError.description'"
>
</myprofile-signup-ui-notification-banner>
<div *ngIf="mode === RegistrationProcess.OWNER">
  <div class="w-full" *ngIf="transactionType$ | async as transactionType">
    <p-text
      size="{base: 'small', m: 'medium' }"
      class="mt-static_m s:mt-static_l text-balance"
      [innerHTML]="'signup.activateAccount.confirmationCode.sentHint' | translate: { MASKED_MOBILE_NO: mobile }"
    ></p-text>
    <p-text
      size="{base: 'small', m: 'medium' }"
      class="m:mt-static_s"
      *ngIf="
        transactionType === TransactionType.SIMPLIFIED_ADD_NON_CONNECT_VEHICLE ||
        transactionType === TransactionType.SIMPLIFIED_ADD_CONNECT_VEHICLE
      "
    >
      <span class="m:mr-static_xs" [innerText]="'signup.simplifiedAddVehicle.confirmation.incorrectNumber.text' | translate"></span>

      <span class="m:mr-static_xs">
        &ngsp;
        <p-link-pure
          [ngClass]="{ 'underline-link': true }"
          data-e2e="simpliefied-add-vehicle-go-to-manual-process"
          [icon]="'none'"
          [myprofileSignupUiTrack]="{
            clickElementType: 'link',
            clickElementId: 'linkout',
            clickElementName: 'link_to_external-target',
            pageName: currentPageName,
            eventAction: 'PAGMyPorsche_SignUpIdentityRequest_Click'
          }"
          (click)="goToManualAddVehicleProcess()"
        >
          <a>
            {{ 'signup.simplifiedAddVehicle.confirmation.incorrectNumber.link.text' | translate }}
          </a>
        </p-link-pure>
      </span>
    </p-text>
    <form
      [formGroup]="formGroup"
      myprofileSignupUiValidationTrack="verificationCodeForm"
      class="mt-static_l"
      data-e2e="confirmation-confirmIdentity-form"
    >
      <p-pin-code
        [name]="'sms-code-owner'"
        [length]="otpLength"
        (update)="onAutoSubmitOTP($event, mode)"
        [loading]="
          (codeStatus$ | async) === ConfirmationCodeStatus.CODE_STATUS_VALIDATION_PENDING ||
          (codeStatus$ | async) === ConfirmationCodeStatus.CODE_STATUS_VALID
        "
        [state]="messageState"
        [message]="messages[0]"
        data-e2e="sms-code-owner"
        #smsCodeOwner
      >
        <span slot="label" id="pin-code" class="text-l m:text-xl">
          {{'signup.activateAccount.confirmationCode.enterHint.label' | translate}}
        </span>
      </p-pin-code>
      <div class="mt-static_l">
        <p-text size="{base: 'small', m: 'medium' }" class="mr-static_xs inline-block">
          <span [innerText]="'signup.activateAccount.confirmationCode.notReceived.label' | translate"></span>
        </p-text>
        <myprofile-signup-ui-timer-label
          [requestCodeTimerLabel]="'signup.activateAccount.confirmationCode.requestNewCode.button.label' | translate"
          [waitingTimerLabelText]="'pida.activateAccount.confirmationCode.requestConfirmationCodeWaiting' | translate"
          [inactiveTimerButton]="
            (otpRequested$ | async) || isCodeStatusPendingOrValid(codeStatus$ | async) || (otpRequestedBlocked$ | async)
          "
          (clickedTimerEvent)="onRequestOTP(true)"
          [myprofileSignupUiTrack]="{
            clickElementType: 'button',
            clickElementId: 'activation_code',
            clickElementName: 'resend_code',
            pageName: currentPageName,
            eventAction: 'PAGMyPorsche_SignUpIdentityRequest_Click'
          }"
        ></myprofile-signup-ui-timer-label>
      </div>
    </form>
  </div>
</div>
<div *ngIf="mode === RegistrationProcess.PROSPECT">
  <p-text class="mt-static_l" [innerHTML]="'signup.register.prospect.verifySms.subline.label' | translate: { mobile: mobile }"></p-text>
  <form
    [formGroup]="formGroup"
    myprofileSignupUiValidationTrack="verificationCodeForm"
    class="mt-static_l"
    data-e2e="confirmation-confirmIdentity-form"
  >
    <p-pin-code
      [label]="'signup.register.prospect.verifySms.input.label' | translate"
      [name]="'sms-code-prospect'"
      [length]="otpLength"
      (update)="onAutoSubmitOTP($event, mode)"
      [loading]="
        codeStatus === ConfirmationCodeStatus.CODE_STATUS_VALIDATION_PENDING ||
        codeStatus === ConfirmationCodeStatus.CODE_STATUS_VALID
      "
      [state]="messageState"
      [message]="messages[0]"
      data-e2e="sms-code-prospect"
      #smsCodeProspect
    ></p-pin-code>
    <div class="mt-static_l">
      <p-text size="small" class="mr-static_xs inline-block">
        <span [innerText]="'signup.register.prospect.verifySms.body.label' | translate"></span>
      </p-text>
      <myprofile-signup-ui-timer-label
        [requestCodeTimerLabel]="'signup.activateAccount.confirmationCode.requestNewCode.button.label' | translate"
        [waitingTimerLabelText]="'pida.activateAccount.confirmationCode.requestConfirmationCodeWaiting' | translate"
        [inactiveTimerButton]="otpRequested"
        (clickedTimerEvent)="onRequestProspectOTP()"
        [myprofileSignupUiTrack]="{
            clickElementType: 'button',
            clickElementId: 'activation_code',
            clickElementName: 'resend_code',
            pageName: currentPageName,
            eventAction: 'PAGMyPorsche_SignUpIdentityRequest_Click'
          }"
      ></myprofile-signup-ui-timer-label>
    </div>
  </form>
</div>
