/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { InjectionToken } from '@angular/core'
import { MaskedRange } from 'imask'

export const LOCALISATION_BASE_URL = new InjectionToken<string>('localisationBasePath')
export const API_KEY = new InjectionToken<string>('profileApiKey')

export const SIGNUP_API_PREFIX = 'signup/'

export const BackendRoutes = {
  CONFIG_BASE: 'configuration/:version/:stage/:country/:locale.json',

  // this locale is not the locale from the url 🤷‍ (it's the f**cked up one e.g. de_AT)
  CONFIG_FOOTER_LEGAL: 'footers/:version/:stage/FOOTER_LEGAL/:locale.json',

  PROFILE: 'profiles/mydata',

  FORM__SCHEMA__SIGNUP: 'schemas/:version/signup-:type/:country/:locale.json',
  FORM__LAYOUT__SIGNUP: 'layouts/:version/signup-:type/:country.json',

  v1: {
    PUBLIC__LEGAL: 'public/v1/:country/:locale/legal',
    PUBLIC__KEYS_PASSWORD: 'public/v1/:country/:locale/keys/password',
    PUBLIC__KEYS_SPIN: 'public/v1/:country/:language/keys/spin',
    PUBLIC__KEYS_PASSWORD_ENCRYPT: 'public/v1/:country/:locale/keys/password/encrypt',
    PUBLIC__ORGANISATION: 'public/v1/:country/:locale/organisation',
    PUBLIC__ORGANISATION__CONTACT_CENTER: 'public/v1/:country/:locale/organisation?orgCountry=:country&orgFunction=contact_centre',
    PUBLIC__FEATURE_ALL: 'public/v1/:country/:locale/features',
    PUBLIC__FEATURE: 'public/v1/:country/:locale/features/:feature',

    REGISTRATION__INVITE: 'registration/v1/:country/:locale/invite',
    REGISTRATION__REGISTER: 'registration/v1/:country/:locale/register',
    REGISTRATION__TRANSACTION: 'registration/v1/:country/:locale/transaction',
    REGISTRATION__ACTION_RESEND: 'registration/v1/:country/:locale/action/resend',
    REGISTRATION__ACTION_SECURE_RESEND: 'registration/v1/:country/:locale/action/secure-resend',
    REGISTRATION__REGISTER_ORGANISATION: 'registration/v1/:country/:locale/register/organisation',
    REGISTRATION__INTERNAL_UPDATE_TRANSACTION: 'registration/v1/internal/update-transaction',
    REGISTRATION__INTERNAL_ACCOUNT_DELETED: 'registration/v1/internal/account-deleted',
    REGISTRATION__INTERNAL_TRANSACTION_EXPIRED: 'registration/v1/internal/transaction-expired',
    CONFIRMATION__CONFIRM: 'confirmation/v1/:country/:locale/confirm',
    CONFIRMATION__SECURE_CONFIRM: 'confirmation/v1/:country/:locale/secure-confirm',
    CONFIRMATION__AUTH: 'confirmation/v1/:country/:locale/auth',
    CONFIRMATION__SECURE_AUTH: 'confirmation/v1/:country/:locale/secure-auth',
    CONFIRMATION__OTP_REQUEST: 'confirmation/v1/:country/:locale/otp/request',
    CONFIRMATION__OTP_VERIFICATION: 'confirmation/v1/:country/:locale/otp/verification',
    CONFIRMATION__INTERNAL_STATE_MACHINE: 'confirmation/v1/:country/:locale/otp/smscode/help',
    CONFIRMATION__SPIN: 'confirmation/v1/:country/:locale/spin',
    CONFIRMATION__TRANSACTION_ID: 'confirmation/v1/:country/:locale/transaction-id',
    CONFIRMATION__PROFILE_EMAIL_VERIFICATION: 'confirmation/v1/:country/:locale/profile-transactions/:tid/verification',

    SHORT_LINK__RESOLVE: 'v1/links/:shortLinkId',
  },
  v2: {
    CONFIRMATION__SPIN: 'confirmation/v2/:country/:locale/spin',
  },
}

/**
 * Constants for the date format placeholders.
 * Separators in the placeholders have no impact. You have to check the placeholder with the format
 */
export const DATE_PLACEHOLDERS = {
  AD: {
    es_ES: 'dd/mm/yyyy',
  },
  AL: {
    en_GB: 'dd/mm/yyyy',
  },
  BE: {
    nl_NL: 'dd/mm/jjjj',
    fr_FR: 'jj/mm/aaaa',
  },
  BA: {
    en_GB: 'dd/mm/yyyy',
  },
  BG: {
    en_GB: 'dd/mm/yyyy',
  },
  CN: {
    zh_CN: 'yyyy年mm月dd日',
    en_GB: 'dd/mm/yyyy',
  },
  DK: {
    dk_DK: 'dd.mm.åååå',
    en_GB: 'dd/mm/yyyy',
  },
  DE: {
    de_DE: 'TT.MM.JJJJ',
  },
  EE: {
    et_EE: 'dd/mm/yyyy',
    ru_RU: 'дд.мм.гггг',
  },
  FI: {
    fi_FI: 'pp.kk.vvvv',
    en_GB: 'dd/mm/yyyy',
  },
  FR: {
    fr_FR: 'jj-mm-aaaa',
  },
  GI: {
    en_GB: 'dd/mm/yyyy',
  },
  GR: {
    en_GB: 'dd/mm/yyyy',
  },
  GB: {
    en_GB: 'dd/mm/yyyy',
  },
  IE: {
    en_GB: 'dd/mm/yyyy',
  },
  IS: {
    en_GB: 'dd/mm/yyyy',
  },
  IT: {
    it_IT: 'gg/mm/aaaa',
  },
  JP: {
    ja_JP: 'yyyy/mm/dd',
    en_GB: 'dd/mm/yyyy',
  },
  KR: {
    en_GB: 'dd/mm/yyyy',
    ko_KR: 'yyyy.mm.dd',
  },
  CA: {
    fr_FR: 'jj-mm-aaaa',
    en_US: 'dd/mm/yyyy',
  },
  HR: {
    en_GB: 'dd/mm/yyyy',
  },
  LV: {
    ru_RU: 'дд.мм.гггг',
    lv_LV: 'dd/mm/yyyy',
  },
  LI: {
    de_DE: 'TT.MM.JJJJ',
    fr_FR: 'jj-mm-aaaa',
    it_IT: 'gg/mm/aaaa',
    en_GB: 'dd/mm/yyyy',
  },
  LT: {
    lt_LT: 'dd/mm/yyyy',
    ru_RU: 'дд.мм.гггг',
  },
  LU: {
    fr_FR: 'jj-mm-aaaa',
    de_DE: 'TT.MM.JJJJ',
  },
  MT: {
    en_GB: 'dd/mm/yyyy',
  },
  MK: {
    en_GB: 'dd/mm/yyyy',
  },
  MC: {
    fr_FR: 'jj-mm-aaaa',
  },
  NL: {
    nl_NL: 'dd-mm-jjjj',
    en_GB: 'dd/mm/yyyy',
  },
  NO: {
    no_NO: 'dd.mm.åååå',
    nb_NO: 'dd.mm.åååå',
    en_GB: 'dd/mm/yyyy',
  },
  AT: {
    de_DE: 'TT.MM.JJJJ',
  },
  PL: {
    pl_PL: 'dd.mm.rrrr',
    en_GB: 'dd/mm/yyyy',
  },
  PT: {
    pt_PT: 'dd/mm/aaaa',
    en_GB: 'dd/mm/yyyy',
  },
  RO: {
    en_GB: 'dd/mm/yyyy',
  },
  RU: {
    ru_RU: 'дд.мм.гггг',
  },
  SE: {
    sv_SE: 'åååå-mm-dd',
    en_GB: 'dd/mm/yyyy',
  },
  CH: {
    de_DE: 'TT.MM.JJJJ',
    fr_FR: 'jj/mm/aaaa',
    it_IT: 'gg/mm/aaaa',
    en_GB: 'dd/mm/yyyy',
  },
  RS: {
    en_GB: 'dd/mm/yyyy',
  },
  SI: {
    en_GB: 'dd/mm/yyyy',
  },
  ES: {
    es_ES: 'dd/mm/aaaa',
  },
  CZ: {
    cs_CZ: 'dd.mm.rrrr',
    en_GB: 'dd/mm/yyyy',
  },
  TR: {
    tr_TR: 'gg.aa.yyyy',
    en_GB: 'dd/mm/yyyy',
  },
  UA: {
    ru_RU: 'дд.мм.гггг',
  },
  HU: {
    en_GB: 'dd/mm/yyyy',
  },
  US: {
    en_US: 'mm/dd/yyyy',
  },
  BY: {
    ru_RU: 'дд.мм.гггг',
  },
  CY: {
    en_GB: 'dd/mm/yyyy',
    ru_RU: 'дд.мм.гггг',
  },
  HK: {
    zh_CN: 'yyyy年mm月dd日',
    en_GB: 'dd/mm/yyyy',
  },
  SG: {
    en_GB: 'dd/mm/yyyy',
  },
  MY: {
    en_GB: 'dd/mm/yyyy',
  },
  MX: {
    es_ES: 'dd/mm/aaaa',
  },
  SK: {
    en_GB: 'dd/mm/yyyy',
    cs_CZ: 'dd.mm.rrrr',
  },
  TW: {
    en_GB: 'dd/mm/yyyy',
    zh_TW: 'yyyy年mm月dd日',
  },
  AU: {
    en_GB: 'dd/mm/yyyy',
  },
  NZ: {
    en_GB: 'dd/mm/yyyy',
  },
  AR: {
    es_ES: 'dd/mm/aaaa',
  },
  AM: {
    en_GB: 'dd/mm/yyyy',
  },
  AZ: {
    en_GB: 'dd/mm/yyyy',
  },
  BH: {
    en_GB: 'dd/mm/yyyy',
  },
  BO: {
    es_ES: 'dd/mm/aaaa',
  },
  BR: {
    en_GB: 'dd/mm/yyyy',
  },
  BN: {
    en_GB: 'dd/mm/yyyy',
  },
  KH: {
    en_GB: 'dd/mm/yyyy',
  },
  CL: {
    es_ES: 'dd/mm/aaaa',
  },
  CO: {
    es_ES: 'dd/mm/aaaa',
  },
  CR: {
    es_ES: 'dd/mm/aaaa',
  },
  DO: {
    es_ES: 'dd/mm/aaaa',
  },
  EC: {
    es_ES: 'dd/mm/aaaa',
  },
  EG: {
    en_GB: 'dd/mm/yyyy',
  },
  SV: {
    es_ES: 'dd/mm/aaaa',
  },
  PF: {
    fr_FR: 'jj/mm/aaaa',
  },
  GE: {
    en_GB: 'dd/mm/yyyy',
  },
  GP: {
    fr_FR: 'jj/mm/aaaa',
  },
  GT: {
    es_ES: 'dd/mm/aaaa',
  },
  HT: {
    fr_FR: 'jj/mm/aaaa',
  },
  HN: {
    es_ES: 'dd/mm/aaaa',
  },
  IN: {
    en_GB: 'dd/mm/yyyy',
  },
  ID: {
    en_GB: 'dd/mm/yyyy',
  },
  IL: {
    en_GB: 'dd/mm/yyyy',
  },
  JO: {
    en_GB: 'dd/mm/yyyy',
  },
  KZ: {
    en_GB: 'dd/mm/yyyy',
  },
  KE: {
    en_GB: 'dd/mm/yyyy',
  },
  KW: {
    en_GB: 'dd/mm/yyyy',
  },
  LB: {
    en_GB: 'dd/mm/yyyy',
  },
  MN: {
    en_GB: 'dd/mm/yyyy',
  },
  MD: {
    en_GB: 'dd/mm/yyyy',
  },
  NC: {
    fr_FR: 'jj/mm/aaaa',
  },
  OM: {
    en_GB: 'dd/mm/yyyy',
  },
  PK: {
    en_GB: 'dd/mm/yyyy',
  },
  PA: {
    es_ES: 'dd/mm/aaaa',
  },
  PY: {
    es_ES: 'dd/mm/aaaa',
  },
  PE: {
    es_ES: 'dd/mm/aaaa',
  },
  PH: {
    en_GB: 'dd/mm/yyyy',
  },
  PR: {
    en_US: 'dd/mm/yyyy',
  },
  QA: {
    en_GB: 'dd/mm/yyyy',
  },
  SA: {
    en_GB: 'dd/mm/yyyy',
  },
  ZA: {
    en_GB: 'dd/mm/yyyy',
  },
  LK: {
    en_GB: 'dd/mm/yyyy',
  },
  SY: {
    en_GB: 'dd/mm/yyyy',
  },
  TH: {
    en_GB: 'dd/mm/yyyy',
  },
  TT: {
    en_GB: 'dd/mm/yyyy',
  },
  UY: {
    es_ES: 'dd/mm/aaaa',
  },
  AE: {
    en_GB: 'dd/mm/yyyy',
  },
  VE: {
    es_ES: 'dd/mm/aaaa',
  },
  VN: {
    en_GB: 'dd/mm/yyyy',
  },
  YE: {
    en_GB: 'dd/mm/yyyy',
  },
}

type rangeType = 'day' | 'month' | 'year'

const allowedNumbers: { [key in rangeType]: { from: number; to: number } } = {
  day: {
    from: 1,
    to: 31,
  },
  month: {
    from: 1,
    to: 12,
  },
  year: {
    from: new Date().getFullYear() - 100,
    to: new Date().getFullYear(),
  },
}

const getBlockType = ({ placeholderChar, rangeType }: { placeholderChar: string; rangeType: rangeType }) => {
  return {
    mask: MaskedRange,
    from: allowedNumbers[rangeType].from,
    to: allowedNumbers[rangeType].to,
    placeholderChar,
  }
}

export const blockTypeMap = {
  dd: getBlockType({ placeholderChar: 'd', rangeType: 'day' }),
  дд: getBlockType({ placeholderChar: 'д', rangeType: 'day' }),
  TT: getBlockType({ placeholderChar: 'T', rangeType: 'day' }),
  jj: getBlockType({ placeholderChar: 'j', rangeType: 'day' }),
  gg: getBlockType({ placeholderChar: 'g', rangeType: 'day' }),
  pp: getBlockType({ placeholderChar: 'p', rangeType: 'day' }),
  aa: getBlockType({ placeholderChar: 'a', rangeType: 'month' }),
  mm: getBlockType({ placeholderChar: 'm', rangeType: 'month' }),
  мм: getBlockType({ placeholderChar: 'м', rangeType: 'month' }),
  MM: getBlockType({ placeholderChar: 'M', rangeType: 'month' }),
  kk: getBlockType({ placeholderChar: 'k', rangeType: 'month' }),
  yyyy: getBlockType({ placeholderChar: 'y', rangeType: 'year' }),
  jjjj: getBlockType({ placeholderChar: 'j', rangeType: 'year' }),
  JJJJ: getBlockType({ placeholderChar: 'J', rangeType: 'year' }),
  aaaa: getBlockType({ placeholderChar: 'a', rangeType: 'year' }),
  åååå: getBlockType({ placeholderChar: 'å', rangeType: 'year' }),
  гггг: getBlockType({ placeholderChar: 'г', rangeType: 'year' }),
  vvvv: getBlockType({ placeholderChar: 'v', rangeType: 'year' }),
  rrrr: getBlockType({ placeholderChar: 'r', rangeType: 'year' }),
}
