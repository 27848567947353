/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */
import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import {PorscheDesignSystemModule} from "@porsche-design-system/components-angular";
import {TranslateModule} from "@ngx-translate/core";

@Component({
    selector: 'myprofile-signup-ui-maintenance',
    imports: [CommonModule, PorscheDesignSystemModule, TranslateModule],
    templateUrl: './maintenance.component.html'
})
export class MaintenanceComponent {}
