/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { take } from 'rxjs/operators'
import { BaseFormControlComponent } from '../base-form-control.component'
import { DynamicFormLayout, DynamicFormLayoutService } from '../../../service/dynamic-form-layout.service'
import { DynamicFormControlLayout } from '../../../model/misc/dynamic-form-control-layout.model'
import { DynamicSelectModel } from '../../../model/select/dynamic-select.model'
import { DynamicFormValidationService } from '../../../service/dynamic-form-validation.service'

export enum DynamicBasicSelectMode {
  DEFAULT,
  SEARCH,
}

@Component({
    selector: 'dynamic-basic-select',
    templateUrl: './dynamic-basic-select.component.html',
    standalone: false
})
export class DynamicBasicSelectComponent extends BaseFormControlComponent implements OnInit {
  @Input() formLayout: DynamicFormLayout
  @Input() group: UntypedFormGroup
  @Input() layout: DynamicFormControlLayout
  @Input() model: DynamicSelectModel<string>

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur: EventEmitter<any> = new EventEmitter()
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: EventEmitter<any> = new EventEmitter()
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() focus: EventEmitter<any> = new EventEmitter()

  public DynamicBasicSelectMode = DynamicBasicSelectMode
  public mode = DynamicBasicSelectMode.DEFAULT
  public modelOptions

  constructor(
    public layoutService: DynamicFormLayoutService,
    public validationService: DynamicFormValidationService,
  ) {
    super(layoutService, validationService)
  }

  public ngOnInit(): void {
    this.model.options$.pipe(take(1)).subscribe((options) => {
      this.modelOptions = options
      this.mode = options.length && options.length >= 5 ? DynamicBasicSelectMode.SEARCH : DynamicBasicSelectMode.DEFAULT
    })
  }
}
