/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component, Input } from '@angular/core'
import { TransactionType } from '../../enums/transaction-type'
import { Select } from '@ngxs/store'
import { ConfirmationSelectors } from '../../state/confirmation/confirmation.selectors'
import { Observable } from 'rxjs'
import { SignupService } from '../../services/signup/signup.service'
import { PidxGoogleTagManagerIntegrationService } from '../../services/google-tag-manager/pidx-google-tag-manager-integration.service'
import { filter } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'
import { LegalCondition } from '../../models/legal-conditions'
import { RegistrationType } from '../../enums/registration-type'
import { PinCodeState } from '@porsche-design-system/components-angular'
import { ConfirmationCodeStatus } from '../../enums/confirmation-code-status'

@Component({
    selector: 'myprofile-signup-ui-confirm-identity',
    templateUrl: './confirm-identity.component.html',
    standalone: false
})
export class ConfirmIdentityComponent {
  @Input() mobile: string
  @Input() currentPageName: string
  @Input() legalConditions: LegalCondition[]

  @Select(ConfirmationSelectors.codeStatus) public readonly codeStatus$: Observable<ConfirmationCodeStatus>

  public messages: string[] = []
  public messageState: PinCodeState = 'none'
  public registrationType: RegistrationType
  public simplifiedAddVehicle: boolean

  constructor(
    private _signupService: SignupService,
    private _tagManagerIntegrationService: PidxGoogleTagManagerIntegrationService,
    private _translateService: TranslateService,
  ) {
    this.registrationType = this._signupService.registrationType
    this.simplifiedAddVehicle = this._signupService.simplifiedAddVehicle

    this.codeStatus$.pipe(filter((status) => status === ConfirmationCodeStatus.CODE_STATUS_VALID)).subscribe(() => {
      this.messageState = 'success'
      this._tagManagerIntegrationService.triggerClickTracking({
        clickElementId: 'activation_code',
        clickElementName: 'approved',
        clickElementType: 'button',
        pageName: 'sign_up/identityverification',
        eventAction: 'PAGMyPorsche_SignUpIdentityVerification_Click',
      })
    })

    this.codeStatus$.pipe(filter((status) => status === ConfirmationCodeStatus.CODE_STATUS_TEMPORARILY_BLOCKED)).subscribe(() => {
      const translationKey = this.isSecondFactorDob ? 'dob' : 'sms'
      this.messageState = 'error'
      this.messages = [this._translateService.instant(`signup.generic.error.${translationKey}.confirmationCodeIsTemporarilyBlocked`)]
    })
  }

  get isSecondFactorDob(): boolean {
    return this.registrationType === RegistrationType.MOBILE && !this.simplifiedAddVehicle
  }

  get isAddVehicleLeanActivation() {
    return (
      this._signupService.isLeanActivationCountry &&
      [
        TransactionType.ADD_CONNECT_VEHICLE,
        TransactionType.ADD_NON_CONNECT_VEHICLE,
        TransactionType.SIMPLIFIED_ADD_CONNECT_VEHICLE,
        TransactionType.SIMPLIFIED_ADD_NON_CONNECT_VEHICLE,
      ].includes(this._signupService.transactionType)
    )
  }

  get leanActivationText() {
    const translated = this._translateService.instant('signup.activateAccount.addVehicle.leanActivation.legalText')
    return translated
      .replace(/{{tacLink}}/, this.legalConditions?.find((lc) => lc.type === 'TAC')?.linkExt)
      .replace(/{{touLink}}/, this.legalConditions?.find((lc) => lc.type === 'TOU')?.linkExt)
      .replace(/{{dpgLink}}/, this.legalConditions?.find((lc) => lc.type === 'DPG')?.linkExt)
  }
}
