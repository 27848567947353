/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

export interface DynamicValidatorDescriptor {
  name: string
  args: any
}

export type DynamicValidatorsConfig = { [validatorKey: string]: any | DynamicValidatorDescriptor }

export enum DynamicFormHook {
  Blur = 'blur',
  Change = 'change',
  Submit = 'submit',
}
