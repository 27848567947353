/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import * as moment from 'moment'
import { ConfigService } from '../../../services/config/config.service'
import { Observable } from 'rxjs'
import { Select, Store } from '@ngxs/store'
import { ConfirmationSelectors } from '../../../state/confirmation/confirmation.selectors'
import { TranslateService } from '@ngx-translate/core'
import { Confirmation } from '../../../state/confirmation/confirmation.actions'
import { filter } from 'rxjs/operators'
import { ConfirmationCodeStatus } from '../../../enums/confirmation-code-status'
import { PidxGoogleTagManagerIntegrationService } from '../../../services/google-tag-manager/pidx-google-tag-manager-integration.service'
import { blockTypeMap, DATE_PLACEHOLDERS } from '../../../constants'
import { SignupService } from '../../../services/signup/signup.service'
import { TransactionType } from '../../../enums/transaction-type'
import ValidateOTP = Confirmation.ValidateOTP

const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY'

@Component({
    selector: 'myprofile-signup-ui-dob-confirm-identity',
    templateUrl: './dob-confirm-identity.component.html',
    standalone: false
})
export class DobConfirmIdentityComponent implements OnInit {
  @Input() currentPageName: string
  @Input() errorMessages: string[]

  @Select(ConfirmationSelectors.codeStatus) public readonly codeStatus$: Observable<ConfirmationCodeStatus>

  @ViewChild('dateOfBirthFormControl') public dateOfBirthFormControl: ElementRef

  public dateOfBirthFormGroup: UntypedFormGroup = new UntypedFormGroup({
    dateOfBirth: new UntypedFormControl(),
  })
  public placeholder: string
  public dateOfBirthMask: any
  public codeStatusLoading = false
  public ConfirmationCodeStatus: ConfirmationCodeStatus

  private locale: string
  private country: string

  constructor(
    private _renderer: Renderer2,
    private configService: ConfigService,
    private _translateService: TranslateService,
    private store: Store,
    private _tagManagerIntegrationService: PidxGoogleTagManagerIntegrationService,
    private signupService: SignupService,
  ) {
    this.codeStatus$.pipe(filter((status) => status === ConfirmationCodeStatus.CODE_STATUS_INVALID)).subscribe(() => {
      const contextDependentError = [TransactionType.SECONDARY, TransactionType.SECONDARY_VEHICLE].includes(
        this.signupService.transactionType,
      )
        ? 'signup.generic.error.dateOfBirth.confirmationCodeIsInvalidSecondary'
        : 'signup.generic.error.dateOfBirth.confirmationCodeIsInvalid'
      this.errorMessages = [this._translateService.instant(contextDependentError)]
      this._tagManagerIntegrationService.triggerLoadTracking({
        pageName: 'sign_up/identityverification',
        eventAction: 'PAGMyPorsche_SignUpIdentityVerificationError_Load',
      })
    })

    this.codeStatus$.pipe().subscribe((codeStatus) => {
      this.codeStatusLoading = codeStatus === ConfirmationCodeStatus.CODE_STATUS_VALIDATION_PENDING
    })
  }

  get dateOfBirthFormControlErrorMessage() {
    return this.errorMessages?.[0]
  }

  async ngOnInit() {
    this.locale = this.configService.getUrlLocale()
    this.country = this.configService.getUrlCountry()
    this.setupDateOfBirthMask()
  }

  private setupDateOfBirthMask() {
    this.placeholder = DATE_PLACEHOLDERS?.[this.country.toUpperCase()]?.[this.locale] || DEFAULT_DATE_FORMAT
    this.dateOfBirthMask = {
      lazy: false,
      mask: this.placeholder,
      blocks: blockTypeMap,
    }
  }

  onSubmitOTP() {
    if (!this.dateOfBirthFormGroup.get('dateOfBirth').value?.length) return
    const dateOfBirth = this.dateOfBirthFormGroup.get('dateOfBirth').value
    const confirmationCode = this.formatDateOfBirth(dateOfBirth)
    this.store.dispatch(new ValidateOTP(confirmationCode))
  }

  private formatDateOfBirth(dateOfBirth) {
    const currentDateFormat = moment.localeData(this.locale).longDateFormat('L')
    return moment(dateOfBirth, currentDateFormat).format('YYYYMMDD')
  }
}
