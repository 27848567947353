<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
  -->

<div [formGroup]="group">
  <p-checkbox-wrapper
    [ngClass]="[getClass('element', 'control'), getClass('grid', 'control')]"
    [hideLabel]="false"
    [state]="controlState"
    [message]="errorMessages[0]"
    [id]="model.id + '-checkboxWrapper'"
  >
    <span slot="label" id="{{model.id + '-checkboxLabel'}}" [innerHTML]="model.label"></span>
    <input
      type="checkbox"
      [attr.aria-labelledby]="model.id + '-checkboxLabel'"
      [checked]="model.checked"
      [formControlName]="model.id"
      [id]="id"
      [indeterminate]="model.indeterminate"
      [name]="model.name"
      [required]="model.required"
      [tabindex]="model.tabIndex"
      [value]="model.value"
      (blur)="onBlur($event)"
      (change)="onChange($event)"
      (focus)="onFocus($event)"
    />
  </p-checkbox-wrapper>
</div>
