/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

export type ConfirmationStep = 'confirmation_introduction' | 'request_sms_code' | 'confirm_identity' | 'set_password' | 'set_spin' | 'activation'

type ConfirmationStepMap = {
  [Step in ConfirmationStep as Uppercase<Step>]: Step
}

export const ConfirmationStep: ConfirmationStepMap = {
  CONFIRMATION_INTRODUCTION: 'confirmation_introduction',
  REQUEST_SMS_CODE: 'request_sms_code',
  CONFIRM_IDENTITY: 'confirm_identity',
  SET_PASSWORD: 'set_password',
  SET_SPIN: 'set_spin',
  ACTIVATION: 'activation',
}
