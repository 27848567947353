/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

export enum ConfirmationCodeStatus {
  CODE_STATUS_DEFAULT = 'CODE_STATUS_DEFAULT',
  CODE_STATUS_VALID = 'CODE_STATUS_VALID',
  CODE_STATUS_INVALID = 'CODE_STATUS_INVALID',
  CODE_STATUS_REQUEST_PENDING = 'CODE_STATUS_REQUEST_PENDING',
  CODE_STATUS_VALIDATION_PENDING = 'CODE_STATUS_VALIDATION_PENDING',
  CODE_STATUS_TEMPORARILY_BLOCKED = 'CODE_STATUS_TEMPORARILY_BLOCKED',
}
