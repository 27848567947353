/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

export interface PasswordStrengthOption {
  id: number
  value: string
  minDiversity: number
  minLength: number
}

export interface PasswordStrength {
  id?: number
  value?: string
  contains?: string[]
  length?: number
}

const passwordStrengthOptions: PasswordStrengthOption[] = [
  {
    id: 0,
    value: 'Too weak',
    minDiversity: 0,
    minLength: 0,
  },
  {
    id: 1,
    value: 'Weak',
    minDiversity: 2,
    minLength: 6,
  },
  {
    id: 2,
    value: 'Medium',
    minDiversity: 4,
    minLength: 8,
  },
  {
    id: 3,
    value: 'Strong',
    minDiversity: 4,
    minLength: 10,
  },
]

export const getPasswordStrength = (password): PasswordStrength => {
  const passwordCopy = password || ''

  const rules = [
    {
      regex: '[a-z]',
      message: 'lowercase',
    },
    {
      regex: '[A-Z]',
      message: 'uppercase',
    },
    {
      regex: '[0-9]',
      message: 'number',
    },
    {
      regex: `.*[^A-Za-z0-9].*`,
      message: 'symbol',
    },
  ]

  const strength: PasswordStrength = {}

  strength.contains = rules.filter((rule) => new RegExp(`${rule.regex}`).test(passwordCopy)).map((rule) => rule.message)

  strength.length = passwordCopy.length

  const fulfilledOptions = passwordStrengthOptions
    .filter((option) => strength.contains.length >= option.minDiversity)
    .filter((option) => strength.length >= option.minLength)
    .sort((o1, o2) => o2.id - o1.id)
    .map((option) => ({ id: option.id, value: option.value }))

  Object.assign(strength, fulfilledOptions[0])

  return strength
}
