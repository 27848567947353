/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http'
import { BehaviorSubject, Observable } from 'rxjs'
import { BackendRoutes, SIGNUP_API_PREFIX } from '../../constants'
import { OrganisationContactInformation } from '../../models/organisation-contact-information'
import { ConfigService } from '../config/config.service'
import * as jose from 'node-jose'
import { map, tap } from 'rxjs/operators'
import { sha256 } from 'js-sha256'
import { TransactionType } from '../../enums/transaction-type'
import { LegalCondition } from '../../models/legal-conditions'
import { RegistrationProcess } from '../../enums/registration-process'
import { RegistrationType } from '../../enums/registration-type'
import { FeatureToggle } from '../../models/feature-toggle'
import { PublicKeySpinEncryption } from '../../models/public-key-spin-encryption'

export const LEAN_ACTIVATION_COUNTRIES = ['AU', 'US', 'CA', 'CN', 'RU', 'NZ', 'SG', 'TW', 'MY']

@Injectable({
  providedIn: 'root',
})
export class SignupService {
  private _headers: HttpHeaders
  private registrationProcess: string

  private _transactionTypesWithSecurityRestrictions = [
    TransactionType.SECONDARY_VEHICLE,
    TransactionType.ADD_CONNECT_VEHICLE,
    TransactionType.ADD_NON_CONNECT_VEHICLE,
    TransactionType.SIMPLIFIED_ADD_CONNECT_VEHICLE,
    TransactionType.SIMPLIFIED_ADD_NON_CONNECT_VEHICLE,
  ]

  private _oneTimeToken$$ = new BehaviorSubject<string>(null)
  private _transactionId$$ = new BehaviorSubject<string>(null)
  private _transactionData$$ = new BehaviorSubject<any>(null)
  private _redirectUrl$$ = new BehaviorSubject<string>(null)
  private _serviceActivationAvailable$$ = new BehaviorSubject<boolean>(false)

  constructor(private _httpClient: HttpClient, private _configService: ConfigService) {}

  public get oneTimeToken(): string {
    return this._oneTimeToken$$.value
  }

  public get transactionId(): string {
    return this._transactionId$$.value
  }

  public get transactionData(): any {
    return this._transactionData$$.value
  }

  public get redirectUrl(): string {
    return this._redirectUrl$$.value
  }

  public get serviceActivationAvailable(): boolean {
    return this._serviceActivationAvailable$$.value
  }

  public resetConfirmationTransactionData(): void {
    this._oneTimeToken$$.next(null)
    this._transactionId$$.next(null)
    this._transactionData$$.next(null)
    this._redirectUrl$$.next(null)
  }

  public get transactionType(): TransactionType {
    if (this._transactionData$$.value) {
      const { type, vehicle, simplified } = this._transactionData$$.value
      if (type === 'OWNER' && vehicle.isConnectable) {
        return TransactionType.CONNECT_OWNER
      } else if (type === 'OWNER' && !vehicle.isConnectable) {
        return TransactionType.NON_CONNECT_OWNER
      } else if (type === 'VEHICLE' && vehicle.isConnectable && !simplified) {
        return TransactionType.ADD_CONNECT_VEHICLE
      } else if (type === 'VEHICLE' && !vehicle.isConnectable && !simplified) {
        return TransactionType.ADD_NON_CONNECT_VEHICLE
      } else if (type === 'VEHICLE' && vehicle.isConnectable && simplified) {
        return TransactionType.SIMPLIFIED_ADD_CONNECT_VEHICLE
      } else if (type === 'VEHICLE' && !vehicle.isConnectable && simplified) {
        return TransactionType.SIMPLIFIED_ADD_NON_CONNECT_VEHICLE
      } else {
        return type
      }
    }
  }

  public get registrationType(): RegistrationType {
    return this._transactionData$$?.value?.registrationType
  }

  public get simplifiedAddVehicle(): boolean {
    return this._transactionData$$?.value?.simplified === true
  }

  public get isLeanActivationCountry(): boolean {
    const country = this._configService.getUrlCountry()
    return LEAN_ACTIVATION_COUNTRIES.includes(country.toUpperCase())
  }

  public get isConnectActivation(): boolean {
    return this._transactionData$$?.value?.vehicle.isConnectable
  }

  public get needsSpin(): boolean {
    return this._transactionData$$?.value?.needsSpin
  }

  public get hashedCiamId(): string | undefined {
    return this._transactionData$$?.value?.ciamId ? sha256(this._transactionData$$?.value?.ciamId) : undefined
  }

  public get hashedPorscheId(): string | undefined {
    return this._transactionData$$?.value?.porscheId ? sha256(this._transactionData$$?.value?.porscheId) : undefined
  }

  public get car() {
    return this._transactionData$$?.value?.vehicle
  }

  public get accountType() {
    return this._transactionData$$?.value?.type
  }

  public getPublicKeyForPasswordEncryption(): Observable<any> {
    const [country, locale] = [this._configService.getUrlCountry(), this._configService.getUrlLocale()]
    const path = BackendRoutes.v1.PUBLIC__KEYS_PASSWORD.replace(':country', country).replace(':locale', locale)

    const url = `${this._configService.getBaseApiUrl()}${SIGNUP_API_PREFIX}${path}`
    return this._httpClient.get<any>(url, { headers: this._headers })
  }

  public getPublicKeyForSpinEncryption(): Observable<PublicKeySpinEncryption> {
    const [country, locale] = [this._configService.getUrlCountry(), this._configService.getUrlLocale()]
    const path = BackendRoutes.v1.PUBLIC__KEYS_SPIN.replace(':country', country).replace(':locale', locale)

    const url = `${this._configService.getBaseApiUrl()}${SIGNUP_API_PREFIX}${path}`
    return this._httpClient.get<PublicKeySpinEncryption>(url, { headers: this._headers })
  }

  public setSPIN(tid: string, otp: string, spin: string): Observable<any> {
    const [country, locale] = [this._configService.getUrlCountry(), this._configService.getUrlLocale()]
    const path = BackendRoutes.v2.CONFIRMATION__SPIN.replace(':country', country).replace(':locale', locale)

    const url = `${this._configService.getBaseApiUrl()}${SIGNUP_API_PREFIX}${path}`

    return this._httpClient.post<any>(url, { tid, otp, spin }, { headers: this._headers })
  }

  public confirmAccount(transactionType: TransactionType, confirmationPayload: any): Observable<any> {
    const useSecureRoute = this._transactionTypesWithSecurityRestrictions.includes(transactionType)
    const route = useSecureRoute ? BackendRoutes.v1.CONFIRMATION__SECURE_CONFIRM : BackendRoutes.v1.CONFIRMATION__CONFIRM
    const [country, locale] = [this._configService.getUrlCountry(), this._configService.getUrlLocale()]
    const path = route.replace(':country', country).replace(':locale', locale)

    const url = `${this._configService.getBaseApiUrl()}${SIGNUP_API_PREFIX}${path}`

    return this._httpClient.post<any>(url, confirmationPayload, { headers: this._headers }).pipe(
      tap((response) => this._redirectUrl$$.next(response.redirectUrl)),
      tap((response) => this._serviceActivationAvailable$$.next(response.serviceActivationAvailable)),
      map((response) => response),
    )
  }

  public validateConfirmationData(tid: string, otp: string, route: string = BackendRoutes.v1.CONFIRMATION__AUTH): Observable<any> {
    this._oneTimeToken$$.next(otp)
    this._transactionId$$.next(tid)

    let _httpParams = new HttpParams()
    _httpParams = _httpParams.append('tid', tid)
    _httpParams = _httpParams.append('otp', otp)

    const [country, locale] = [this._configService.getUrlCountry(), this._configService.getUrlLocale()]
    const path = route.replace(':country', country).replace(':locale', locale)

    const url = `${this._configService.getBaseApiUrl()}${SIGNUP_API_PREFIX}${path}`
    return this._httpClient.get<any>(url, { headers: this._headers, params: _httpParams }).pipe(
      tap((transactionData) => this._transactionData$$.next(transactionData)),
      map((transactionData) => transactionData),
    )
  }

  public validateSecureConfirmationData(tid: string, otp: string): Observable<any> {
    return this.validateConfirmationData(tid, otp, BackendRoutes.v1.CONFIRMATION__SECURE_AUTH)
  }

  public resendRegistrationByPorscheId(porscheId: string, type: 'EMAIL' | 'SMS' = 'EMAIL'): Observable<any> {
    const [country, locale] = [this._configService.getUrlCountry(), this._configService.getUrlLocale()]
    const route =
      this.registrationProcess === RegistrationProcess.ORGANISATION
        ? BackendRoutes.v1.REGISTRATION__ACTION_SECURE_RESEND
        : BackendRoutes.v1.REGISTRATION__ACTION_RESEND
    const path = route.replace(':country', country).replace(':locale', locale)

    const url = `${this._configService.getBaseApiUrl()}${SIGNUP_API_PREFIX}${path}`
    return this._httpClient.post<any>(url, { porscheId, authtype: type }, { headers: this._headers })
  }

  public createUser(
    userRegistrationPayload: any,
    registrationProcess: RegistrationProcess = RegistrationProcess.PROSPECT,
    targetUrl?: string,
    targetClientId?: string,
  ): Observable<HttpResponse<any>> {
    this.registrationProcess = registrationProcess.toUpperCase()
    const [country, locale] = [this._configService.getUrlCountry(), this._configService.getUrlLocale()]
    const baseUrl =
      registrationProcess.toUpperCase() === RegistrationProcess.ORGANISATION
        ? BackendRoutes.v1.REGISTRATION__REGISTER_ORGANISATION
        : BackendRoutes.v1.REGISTRATION__REGISTER
    const path = baseUrl.replace(':country', country).replace(':locale', locale).replace(':type', registrationProcess.toLowerCase())

    const url = `${this._configService.getBaseApiUrl()}${SIGNUP_API_PREFIX}${path}`

    let _httpParams = new HttpParams()
    if (targetUrl) {
      _httpParams = _httpParams.append('targetUrl', targetUrl)
    }

    if (targetClientId) {
      this._headers = new HttpHeaders().set('x-target-client-id', targetClientId)
    }

    return this._httpClient.post<any>(url, userRegistrationPayload, {
      headers: this._headers,
      params: _httpParams,
      observe: 'response',
    })
  }

  public getLegalConditions(): Observable<LegalCondition[]> {
    const [country, locale] = [this._configService.getUrlCountry(), this._configService.getUrlLocale()]
    const path = BackendRoutes.v1.PUBLIC__LEGAL.replace(':country', country).replace(':locale', locale)

    const url = `${this._configService.getBaseApiUrl()}${SIGNUP_API_PREFIX}${path}`
    return this._httpClient.get<any>(url)
  }

  public getFeatureToggles(): Observable<FeatureToggle[]> {
    const [country, locale] = [this._configService.getUrlCountry(), this._configService.getUrlLocale()]
    const path = BackendRoutes.v1.PUBLIC__FEATURE_ALL.replace(':country', country).replace(':locale', locale)

    const url = `${this._configService.getBaseApiUrl()}${SIGNUP_API_PREFIX}${path}`
    return this._httpClient.get<FeatureToggle[]>(url)
  }

  public getContactOrganisation(): Observable<OrganisationContactInformation[]> {
    const [country, locale] = [this._configService.getUrlCountry(), this._configService.getUrlLocale()]
    const path = BackendRoutes.v1.PUBLIC__ORGANISATION__CONTACT_CENTER.replace(new RegExp(':country', 'g'), country).replace(
      ':locale',
      locale,
    )

    const url = `${this._configService.getBaseApiUrl()}${SIGNUP_API_PREFIX}${path}`
    return this._httpClient.get<OrganisationContactInformation[]>(url)
  }

  public requestOTP(): Observable<any> {
    const [country, locale] = [this._configService.getUrlCountry(), this._configService.getUrlLocale()]
    const path = BackendRoutes.v1.CONFIRMATION__OTP_REQUEST.replace(':country', country).replace(':locale', locale)

    const url = `${this._configService.getBaseApiUrl()}${SIGNUP_API_PREFIX}${path}`
    const otpPayload = {
      tid: this.transactionId,
      type: 'SMS',
    }

    return this._httpClient.post<any>(url, otpPayload, { headers: this._headers })
  }

  public verifyOTP(otp: string, type: 'EMAIL' | 'SMS' | 'DOB' = 'SMS'): Observable<any> {
    const [country, locale] = [this._configService.getUrlCountry(), this._configService.getUrlLocale()]
    const path = BackendRoutes.v1.CONFIRMATION__OTP_VERIFICATION.replace(':country', country).replace(':locale', locale)

    const url = `${this._configService.getBaseApiUrl()}${SIGNUP_API_PREFIX}${path}`
    const otpPayload = {
      tid: this.transactionId,
      otp,
      type,
    }

    return this._httpClient.post<any>(url, otpPayload, {
      headers: this._headers,
      observe: 'response',
    })
  }

  public verifyOTPAndGetTransactionId(
    porscheId: string,
    otp: string,
    transactionType: TransactionType,
    type: 'EMAIL' | 'SMS' | 'DOB' = 'SMS',
  ): Observable<any> {
    const [country, locale] = [this._configService.getUrlCountry(), this._configService.getUrlLocale()]
    const path = BackendRoutes.v1.CONFIRMATION__TRANSACTION_ID.replace(':country', country).replace(':locale', locale)

    const url = `${this._configService.getBaseApiUrl()}${SIGNUP_API_PREFIX}${path}`

    let _httpParams = new HttpParams()
    _httpParams = _httpParams.append('porscheId', porscheId)
    _httpParams = _httpParams.append('otp', otp)
    _httpParams = _httpParams.append('authType', type)
    _httpParams = _httpParams.append('transactionType', transactionType)

    return this._httpClient.get<any>(url, { headers: this._headers, params: _httpParams })
  }

  public verifyEmailOTPAndTransactionId(transactionId: string, verificationPayload: { otp: string }): Observable<any> {
    const [country, locale] = [this._configService.getUrlCountry(), this._configService.getUrlLocale()]
    const path = BackendRoutes.v1.CONFIRMATION__PROFILE_EMAIL_VERIFICATION.replace(':country', country)
      .replace(':locale', locale)
      .replace(':tid', transactionId)

    const url = `${this._configService.getBaseApiUrl()}${SIGNUP_API_PREFIX}${path}`

    return this._httpClient.post(url, verificationPayload, { headers: this._headers })
  }

  public async encryptPassword(publicKeyJSON: any, value: string) {
    let publicKey: any
    try {
      publicKey = await jose.JWK.asKey(publicKeyJSON)
    } catch (error) {
      console.log('Error loading the key: ', error)
    }

    try {
      const data = jose.util.base64url.encode(value, 'utf8')
      const buffer = jose.util.asBuffer(data)
      return jose.JWE.createEncrypt({ format: 'compact' }, publicKey).update(buffer).final()
    } catch (error) {
      console.log('Error encrypting the value: ', error)
    }
  }

  public async encryptSPIN(publicKeyJSON: PublicKeySpinEncryption, value: string) {
    try {
      const publicKey = await crypto.subtle.importKey(
        'jwk',
        publicKeyJSON,
        {
          name: 'RSA-OAEP',
          hash: 'SHA-512',
        },
        false,
        ['encrypt'],
      )
      const buffer = await crypto.subtle.encrypt('RSA-OAEP', publicKey, this.stringToArrayBuffer(value))
      return btoa(String.fromCharCode(...new Uint8Array(buffer)))
    } catch (error) {
      console.log('Error encrypting the value: ', error)
    }
  }

  private stringToArrayBuffer(str) {
    const buf = new ArrayBuffer(str.length)
    const bufView = new Uint8Array(buf)
    for (let i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i)
    }
    return buf
  }
}
