/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component, OnInit } from '@angular/core'
import { LoadingService } from '../../services/loading/loading.service'

@Component({
    selector: 'myprofile-signup-ui-loading-indicator',
    templateUrl: './loading-indicator.component.html',
    standalone: false
})
export class LoadingIndicatorComponent implements OnInit {
  public isLoading = false
  public labelKey = ''

  constructor(private _loadingService: LoadingService) {}

  ngOnInit(): void {
    this._loadingService.state.subscribe((loadingState) => (this.isLoading = loadingState))
    this._loadingService.labelKey.subscribe((loadingLabelKey) => (this.labelKey = loadingLabelKey))
  }
}
