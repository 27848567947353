/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component, EventEmitter } from '@angular/core'
import { AbstractControl, UntypedFormGroup } from '@angular/forms'
import { DynamicFormControl } from '../dynamic-form-control.interface'
import { DynamicFormControlModel } from '../../model/dynamic-form-control.model'
import {
  DynamicFormControlLayout,
  DynamicFormControlLayoutContext,
  DynamicFormControlLayoutPlace,
} from '../../model/misc/dynamic-form-control-layout.model'
import { DynamicFormValidationService } from '../../service/dynamic-form-validation.service'
import { DynamicFormLayout, DynamicFormLayoutService } from '../../service/dynamic-form-layout.service'

/**
 * Abstract component which acts as the base component for all individual form controls e.g. input, select etc.
 * @Component({ template: '' }) is necessary to tell the angular injector/compiler that its a component
 */
@Component({
    template: '',
    standalone: false
})
export class BaseFormControlComponent implements DynamicFormControl {
  public formLayout: DynamicFormLayout
  public group: UntypedFormGroup
  public layout: DynamicFormControlLayout
  public model: DynamicFormControlModel
  public blur: EventEmitter<any>
  public change: EventEmitter<any>
  public focus: EventEmitter<any>
  public submitted = false

  constructor(
    public layoutService: DynamicFormLayoutService,
    public validationService: DynamicFormValidationService,
  ) {}

  private _hasFocus = false

  get hasFocus(): boolean {
    return this._hasFocus
  }

  get control(): AbstractControl | never {
    const control = this.group.get(this.model.id)

    if (control === null) {
      throw new Error(`form group does not contain an abstract control with id ${this.model.id}`)
    }

    return control as AbstractControl
  }

  get id(): string {
    return this.layoutService.getElementId(this.model)
  }

  get isInvalid(): boolean {
    return this.control.invalid
  }

  get isValid(): boolean {
    return this.control.valid
  }

  get errorMessages(): string[] {
    return this.validationService.createErrorMessages(this.control, this.model)
  }

  get showErrorMessages(): boolean {
    return this.validationService.showErrorMessages(this.control, this.model, this.hasFocus)
  }

  getClass(
    context: DynamicFormControlLayoutContext,
    place: DynamicFormControlLayoutPlace,
    model: DynamicFormControlModel = this.model,
  ): string {
    const controlLayout =
      model === this.model
        ? this.layout
        : this.layoutService.findByModel(model, this.formLayout) || (model.layout as DynamicFormControlLayout)

    return this.layoutService.getClass(controlLayout, context, place)
  }

  onBlur($event: any) {
    if ($event instanceof Event) {
      $event.stopPropagation()
    }

    this._hasFocus = false

    const control = this.group.get(this.model.id)
    const trimmed = this.trimControlValue(control)
    if (trimmed) {
      this.updateEventValue($event, control.value)
    }

    this.blur.emit($event)
  }

  onChange($event: any) {
    if ($event instanceof Event) {
      $event.stopPropagation()
    }

    const control = this.group.get(this.model.id)
    const trimmed = this.trimControlValue(control)
    if (trimmed) {
      this.updateEventValue($event, control.value)
    }

    this.change.emit($event)
  }

  onFocus($event: any) {
    if ($event instanceof Event) {
      $event.stopPropagation()
    }

    this._hasFocus = true
    this.focus.emit($event)
  }

  protected trimControlValue(control: AbstractControl): boolean {
    let trimmed = false
    if (control && control.value && typeof control.value === 'string') {
      control.setValue(control.value.trim())
      trimmed = true
    }

    return trimmed
  }

  protected updateEventValue($event: any, newValue: string) {
    if ($event?.target?.value && typeof $event?.target?.value === 'string') {
      $event.target.value = newValue
    }
  }
}
