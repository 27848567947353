/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { HttpClient } from '@angular/common/http'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { ConfigService } from './config.service'

export function HttpLoaderFactory(_http: HttpClient, _configService: ConfigService) {
  return new TranslateHttpLoader(_http, _configService.getTranslationsUrl, '.json')
}
