<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
  -->

<p-text size="{base: 'small', m: 'medium' }" class="mt-static_l" [innerText]="'signup.confirm.owner.confirmIdentity.dateOfBirth.label' | translate"></p-text>
<form [formGroup]="dateOfBirthFormGroup">
  <p-text-field-wrapper
    class="mt-static_l w-1/2 s:w-1/3"
    [label]="'signup.confirm.owner.confirmIdentity.dateOfBirth.input.label' | translate"
    [state]="!!dateOfBirthFormControlErrorMessage ? 'error' : 'none'"
    [attr.data-has-error]="!!dateOfBirthFormControlErrorMessage"
  >
    <span slot="message" class="l:w-[40vw] xxl:w-[30vw] m:text-balance">{{dateOfBirthFormControlErrorMessage}}</span>

    <input
      id="dateOfBirthInputField"
      #dateOfBirthFormControl
      class="fs-exclude m:max-w-fit"
      type="text"
      inputmode="numeric"
      name="dateOfBirth"
      formControlName="dateOfBirth"
      [imask]="dateOfBirthMask"
      [unmask]="true"
      [placeholder]="placeholder"
    />

  </p-text-field-wrapper>
  <p-button
    (click)="onSubmitOTP()"
    class="mt-m w-full xs:w-auto"
    variant="primary"
    data-e2e="dob-submit-button"
    [disabled]="codeStatusLoading"
    [loading]="codeStatusLoading"
    [myprofileSignupUiTrack]="{
      clickElementType: 'button',
      clickElementId: 'call_to_action',
      clickElementName: 'confirm_dob',
      pageName: currentPageName,
      eventAction: 'PAGMyPorsche_SignUpConfirmDob_Click'
    }"
  >
    {{ 'signup.confirm.owner.confirmIdentity.dateOfBirth.button.label' | translate }}
  </p-button>
</form>
