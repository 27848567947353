/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import {Inject, Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from "@angular/router";
import {WINDOW} from "../utils";
import {ConfigService} from "../config/config.service";

@Injectable({
  providedIn: 'root'
})
export class RegisterGuardService implements CanActivate {
  constructor(
    @Inject(WINDOW) private _window: Window,
    private _configService: ConfigService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const endsWithRegister = state.url.endsWith('/register');

    const hasRequiredOrganisationTypeParam = route.queryParamMap.has('type');
    const organisationTypeValue= route.queryParamMap.get('type')

    if (endsWithRegister && (!hasRequiredOrganisationTypeParam || organisationTypeValue !== 'organisation')) {
      const redirectUrl = this._configService.getExternalUrl('personalData', 'email')
      this._window.location.href = redirectUrl
    }

    return true;
  }
}
