<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
  -->

<div [formGroup]="group">
  <p-select-wrapper
    [dropdownDirection]="'auto'"
    [filter]="this.mode === DynamicBasicSelectMode.SEARCH"
    [label]="model.label"
    [message]="model.required && model.errorMessages ? model.errorMessages.required : 'This input is required'"
    [state]="control && control.invalid && control.touched ? 'error' : 'none'"
    [theme]="'light'"
  >
    <select [formControlName]="model.id" [name]="model.id" [required]="model.required" [tabindex]="model.tabIndex">
      <option [value]="null" [hidden]="true" [selected]="true"></option>
      <option *ngFor="let option of this.modelOptions" [value]="option.value" [disabled]="option.disabled" [hidden]="option.hidden">
        {{ option.label }}
      </option>
    </select>
  </p-select-wrapper>
</div>
