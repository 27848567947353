/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

export enum ERROR_KEY {
  GENERIC = 'GENERIC',
  BUSINESS_EXCEPTION_TRANSACTION_AUTHENTICATION_OPTION_IS_EXPIRED = 'BUSINESS_EXCEPTION_TRANSACTION_AUTHENTICATION_OPTION_IS_EXPIRED',
  BUSINESS_EXCEPTION_TRANSACTION_AUTHENTICATION_OPTION_IS_EXPIRED_PROSPECT = 'BUSINESS_EXCEPTION_TRANSACTION_AUTHENTICATION_OPTION_IS_EXPIRED_PROSPECT',
  BUSINESS_EXCEPTION_TRANSACTION_AUTHENTICATION_OPTION_IS_EXPIRED_SECONDARY = 'BUSINESS_EXCEPTION_TRANSACTION_AUTHENTICATION_OPTION_IS_EXPIRED_SECONDARY',
  BUSINESS_EXCEPTION_TRANSACTION_ALREADY_CONFIRMED_OR_ABORTED = 'BUSINESS_EXCEPTION_TRANSACTION_ALREADY_CONFIRMED_OR_ABORTED',
  GENERIC_EXCEPTION_EMAIL_VERIFICATION_FAILED = 'GENERIC_EXCEPTION_EMAIL_VERIFICATION_FAILED',
  BUSINESS_EXCEPTION_EMAIL_VERIFICATION_EXPIRED = 'BUSINESS_EXCEPTION_EMAIL_VERIFICATION_EXPIRED',
  BUSINESS_EXCEPTION_EMAIL_VERIFICATION_CONFIRMED_OR_ABORTED = 'BUSINESS_EXCEPTION_EMAIL_VERIFICATION_CONFIRMED_OR_ABORTED',
  VALIDATION_EXCEPTION_EMAIL_VERIFICATION_INVALID_OTP = 'VALIDATION_EXCEPTION_EMAIL_VERIFICATION_INVALID_OTP',
  BUSINESS_EXCEPTION_TRANSACTION_BLACKLISTED_PORSCHE_ID = 'BUSINESS_EXCEPTION_TRANSACTION_BLACKLISTED_PORSCHE_ID',
  BUSINESS_CONSTRAINT_PROFILE_TMP_DATA_NOT_FOUND = 'BUSINESS_CONSTRAINT_PROFILE_TMP_DATA_NOT_FOUND',

  RESOURCE_NOT_FOUND_SHORTLINK = 'RESOURCE_NOT_FOUND_SHORTLINK',
}

export const errorPageData = new Map<ERROR_KEY, ErrorPageData>([
  [
    ERROR_KEY.GENERIC,
    {
      headline: 'signup.errorPage.unknownError.headline',
      subline: 'signup.errorPage.unknownError.subline',
      button: {
        label: 'signup.errorPage.unknownError.button.label',
        type: 'tertiary',
        externalTarget: 'porscheCom',
      },
      image: 'assets/images/error-generic@1536w1x.jpg',
      responsiveImages: [
        {
          type: 'image/webp',
          srcSet: 'assets/images/error-generic@1536w1x.webp 1x',
        },
        {
          type: 'image/jpg',
          srcSet: 'assets/images/error-generic@1536w1x.jpg 1x',
        },
      ],
    },
  ],
  [
    ERROR_KEY.BUSINESS_EXCEPTION_TRANSACTION_BLACKLISTED_PORSCHE_ID,
    {
      headline: 'signup.errorPage.emailDeny.headline',
      subline: 'signup.errorPage.emailDeny.subline',
      button: {
        label: 'signup.errorPage.unknownError.button.label',
        type: 'tertiary',
        externalTarget: 'porscheCom',
      },
      image: 'assets/images/error-generic@1536w1x.jpg',
      responsiveImages: [
        {
          type: 'image/webp',
          srcSet: 'assets/images/error-generic@1536w1x.webp 1x',
        },
        {
          type: 'image/jpg',
          srcSet: 'assets/images/error-generic@1536w1x.jpg 1x',
        },
      ],
    },
  ],
  [
    ERROR_KEY.BUSINESS_EXCEPTION_TRANSACTION_AUTHENTICATION_OPTION_IS_EXPIRED,
    {
      headline: 'signup.owner.errorPage.expiredLink.headline',
      subline: 'signup.owner.errorPage.expiredLink.subline',
      image: 'assets/images/error-expired@1536w1x.jpg',
      responsiveImages: [
        {
          type: 'image/webp',
          srcSet: 'assets/images/error-expired@1536w1x.webp 1x',
        },
        {
          type: 'image/jpg',
          srcSet: 'assets/images/error-expired@1536w1x.jpg 1x',
        },
      ],
    },
  ],
  [
    ERROR_KEY.RESOURCE_NOT_FOUND_SHORTLINK,
    {
      headline: 'signup.owner.errorPage.expiredLink.headline',
      subline: 'signup.owner.errorPage.expiredLink.subline',
      image: 'assets/images/error-expired@1536w1x.jpg',
      responsiveImages: [
        {
          type: 'image/webp',
          srcSet: 'assets/images/error-expired@1536w1x.webp 1x',
        },
        {
          type: 'image/jpg',
          srcSet: 'assets/images/error-expired@1536w1x.jpg 1x',
        },
      ],
    },
  ],
  [
    ERROR_KEY.BUSINESS_CONSTRAINT_PROFILE_TMP_DATA_NOT_FOUND,
    {
      headline: 'signup.owner.errorPage.expiredLink.headline',
      subline: 'signup.owner.errorPage.expiredLink.subline',
      image: 'assets/images/error-expired@1536w1x.jpg',
      responsiveImages: [
        {
          type: 'image/webp',
          srcSet: 'assets/images/error-expired@1536w1x.webp 1x',
        },
        {
          type: 'image/jpg',
          srcSet: 'assets/images/error-expired@1536w1x.jpg 1x',
        },
      ],
    },
  ],
  [
    ERROR_KEY.BUSINESS_EXCEPTION_TRANSACTION_AUTHENTICATION_OPTION_IS_EXPIRED_PROSPECT,
    {
      headline: 'signup.prospect.errorPage.expiredLink.headline',
      subline: 'signup.prospect.errorPage.expiredLink.subline',
      button: {
        label: 'signup.prospect.errorPage.create.button',
        type: 'tertiary',
        target: 'register',
      },
      image: 'assets/images/error-expired@1536w1x.jpg',
      responsiveImages: [
        {
          type: 'image/webp',
          srcSet: 'assets/images/error-expired@1536w1x.webp 1x',
        },
        {
          type: 'image/jpg',
          srcSet: 'assets/images/error-expired@1536w1x.jpg 1x',
        },
      ],
    },
  ],
  [
    ERROR_KEY.BUSINESS_EXCEPTION_TRANSACTION_AUTHENTICATION_OPTION_IS_EXPIRED_SECONDARY,
    {
      headline: 'signup.prospect.errorPage.expiredLink.headline',
      subline: 'signup.secondary.errorPage.expiredLink.subline',
      button: {
        label: 'signup.prospect.errorPage.create.button',
        type: 'tertiary',
        target: 'register',
      },
      image: 'assets/images/error-expired@1536w1x.jpg',
      responsiveImages: [
        {
          type: 'image/webp',
          srcSet: 'assets/images/error-expired@1536w1x.webp 1x',
        },
        {
          type: 'image/jpg',
          srcSet: 'assets/images/error-expired@1536w1x.jpg 1x',
        },
      ],
    },
  ],
  [
    ERROR_KEY.BUSINESS_EXCEPTION_TRANSACTION_ALREADY_CONFIRMED_OR_ABORTED,
    {
      headline: 'signup.errorPage.alreadyConfirmed.headline',
      subline: 'signup.errorPage.alreadyConfirmed.subline',
      image: 'assets/images/error-already-confirmed@1536w2x.jpg',
      textInside: true,
      redirect: {
        externalTarget: 'myPorscheHome',
      },
      responsiveImages: [
        {
          media: '(max-width: 639px)',
          type: 'image/webp',
          srcSet: 'assets/images/error-already-confirmed@576w1x.webp 1x, assets/images/error-already-confirmed@576w2x.webp 2x',
        },
        {
          media: '(max-width: 639px)',
          type: 'image/jpg',
          srcSet: 'assets/images/error-already-confirmed@576w1x.jpg 1x, assets/images/error-already-confirmed@576w2x.jpg 2x',
        },
        {
          type: 'image/webp',
          srcSet: 'assets/images/error-already-confirmed@1536w1x.webp 1x, assets/images/error-already-confirmed@1536w2x.webp 2x',
        },
        {
          type: 'image/jpg',
          srcSet: 'assets/images/error-already-confirmed@1536w1x.jpg 1x, assets/images/error-already-confirmed@1536w2x.jpg 2x',
        },
      ],
    },
  ],
  [
    ERROR_KEY.GENERIC_EXCEPTION_EMAIL_VERIFICATION_FAILED,
    {
      headline: 'signup.emailVerification.errorPage.unknownError.headline',
      subline: 'signup.emailVerification.errorPage.unknownError.subline',
      image: 'assets/images/error-generic@1536w1x.jpg',
      textInside: false,
      button: {
        label: 'signup.emailVerification.errorPage.button',
        type: 'primary',
        externalTarget: 'personalData',
      },
      responsiveImages: [
        {
          type: 'image/webp',
          srcSet: 'assets/images/error-generic@1536w1x.webp 1x',
        },
        {
          type: 'image/jpg',
          srcSet: 'assets/images/error-generic@1536w1x.jpg 1x',
        },
      ],
    },
  ],
  [
    ERROR_KEY.BUSINESS_EXCEPTION_EMAIL_VERIFICATION_EXPIRED,
    {
      headline: 'signup.emailVerification.errorPage.expired.headline',
      subline: 'signup.emailVerification.errorPage.expired.subline',
      image: 'assets/images/error-expired@1536w1x.jpg',
      textInside: false,
      button: {
        label: 'signup.emailVerification.errorPage.button',
        type: 'primary',
        externalTarget: 'personalData',
      },
      responsiveImages: [
        {
          type: 'image/webp',
          srcSet: 'assets/images/error-expired@1536w1x.webp 1x',
        },
        {
          type: 'image/jpg',
          srcSet: 'assets/images/error-expired@1536w1x.jpg 1x',
        },
      ],
    },
  ],
  [
    ERROR_KEY.VALIDATION_EXCEPTION_EMAIL_VERIFICATION_INVALID_OTP,
    {
      headline: 'signup.emailVerification.errorPage.invalid.headline',
      subline: 'signup.emailVerification.errorPage.invalid.subline',
      image: 'assets/images/error-invalid@1536w1x.jpg',
      textInside: false,
      button: {
        label: 'signup.emailVerification.errorPage.button',
        type: 'primary',
        externalTarget: 'personalData',
      },
      responsiveImages: [
        {
          type: 'image/webp',
          srcSet: 'assets/images/error-invalid@1536w1x.webp 1x',
        },
        {
          type: 'image/jpg',
          srcSet: 'assets/images/error-invalid@1536w1x.jpg 1x',
        },
      ],
    },
  ],
  [
    ERROR_KEY.BUSINESS_EXCEPTION_EMAIL_VERIFICATION_CONFIRMED_OR_ABORTED,
    {
      headline: 'signup.emailVerification.errorPage.alreadyConfirmed.headline',
      subline: 'signup.emailVerification.errorPage.alreadyConfirmed.subline',
      image: 'assets/images/error-already-confirmed@1536w2x.jpg',
      textInside: false,
      button: {
        label: 'signup.emailVerification.errorPage.button',
        type: 'primary',
        externalTarget: 'personalData',
      },
      responsiveImages: [
        {
          media: '(max-width: 639px)',
          type: 'image/webp',
          srcSet: 'assets/images/error-already-confirmed@576w1x.webp 1x, assets/images/error-already-confirmed@576w2x.webp 2x',
        },
        {
          media: '(max-width: 639px)',
          type: 'image/jpg',
          srcSet: 'assets/images/error-already-confirmed@576w1x.jpg 1x, assets/images/error-already-confirmed@576w2x.jpg 2x',
        },
        {
          type: 'image/webp',
          srcSet: 'assets/images/error-already-confirmed@1536w1x.webp 1x, assets/images/error-already-confirmed@1536w2x.webp 2x',
        },
        {
          type: 'image/jpg',
          srcSet: 'assets/images/error-already-confirmed@1536w1x.jpg 1x, assets/images/error-already-confirmed@1536w2x.jpg 2x',
        },
      ],
    },
  ],
])

export interface ErrorPageImageData {
  media?: string
  type: string
  srcSet: string
}

export interface ErrorPageButtonData {
  label: string
  target?: string
  externalTarget?: string
  action?: () => void
  type?: 'primary' | 'secondary' | 'tertiary'
}

export interface ErrorPageRedirectData {
  timeout?: number
  target?: string
  externalTarget?: string
}

export interface ErrorPageData {
  error?: ERROR_KEY
  textInside?: boolean
  headline: string
  subline: string
  button?: ErrorPageButtonData
  redirect?: ErrorPageRedirectData
  image?: string
  responsiveImages: ErrorPageImageData[]
}
