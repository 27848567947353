/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { Inject, Injectable, Optional } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { mergeMap } from 'rxjs/operators'
import { EMPTY, from, Observable, of, Subject } from 'rxjs'
import { WINDOW, WindowWrapper } from '../utils'
import { ConfigService } from '../config/config.service'
import { CustomLocaleLoaderService } from '../custom-locale-loader.service'
import { LocaleInformation } from '../config/config.interface'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'

export abstract class LocaleHandlingConfig {
  localeHeaderWhitelistUrl?: string | RegExp | (string | RegExp)[]
  localeHeaderNameCountry?: string
  localeHeaderNameLocale?: string
  translateLoaderProvider?: TranslateHttpLoader
}

@Injectable({
  providedIn: 'root',
})
export class LocaleHandlingService {
  private readonly defaultConfig: LocaleHandlingConfig = {
    localeHeaderWhitelistUrl: null,
    localeHeaderNameCountry: 'x-vrs-url-country',
    localeHeaderNameLocale: 'x-vrs-url-language',
  }

  private readonly config: LocaleHandlingConfig
  private initializedSubject = new Subject<void>()
  private initializedPromise = this.initializedSubject.toPromise()

  constructor(
    @Inject(WINDOW) private window: WindowWrapper,
    @Optional() private localeHandlingConfig: LocaleHandlingConfig,
    protected translate: TranslateService,
    protected configService: ConfigService,
    protected localeLoaderService: CustomLocaleLoaderService,
  ) {
    this.config = {
      ...this.defaultConfig,
      ...this.localeHandlingConfig,
    }
  }

  check(
    localeInformation: LocaleInformation,
    validLocale: () => Promise<boolean>,
    invalidLocale: () => Promise<boolean>,
  ): Promise<boolean> {
    if (localeInformation && localeInformation.translationFileName && localeInformation.angularLocale) {
      return validLocale()
    }
    return invalidLocale()
  }

  async setup(): Promise<boolean> {
    const result = await this.check(
      this.configService.getLocaleInformation(),
      () => this.validLocalePromise(),
      () => this.invalidLocalePromise(),
    )

    this.initializedSubject.next()
    this.initializedSubject.complete()
    return result
  }

  initialized(): Promise<void> {
    return this.initializedPromise
  }

  getConfig(): LocaleHandlingConfig {
    return this.config
  }

  private async validLocalePromise(): Promise<boolean> {
    const angularLocale = this.configService.getAngularLocale()
    const fallbackTranslationFilename = this.configService.getFallbackTranslationFileName()

    this.update(angularLocale)

    await Promise.all([
      this.translate.use(this.configService.getTranslationFileName()).toPromise(),
      this.loadFallbackTranslationsIfAvailable(fallbackTranslationFilename),
      this.loadAngularLocaleData(angularLocale),
    ])

    return true
  }

  private async invalidLocalePromise(): Promise<boolean> {
    this.window.location.href = this.configService.getInvalidLocaleRedirectUrl()
    return false
  }

  private async loadFallbackTranslationsIfAvailable(fallbackTranslationFilename: string): Promise<string> {
    return this.queue(fallbackTranslationFilename)
      .pipe(mergeMap((locale) => from(this.loadFallbackTranslations(locale))))
      .toPromise()
  }

  queue(fallback: string): Observable<string> {
    if (fallback) {
      return of(fallback)
    }

    return EMPTY
  }

  update(locale: string) {
    const htmlElement: HTMLHtmlElement = document.querySelector('html')
    htmlElement.lang = locale
  }

  private async loadFallbackTranslations(locale: string): Promise<string> {
    /**
     * preload translations because setDefaultLang doesn't provide
     * an observable that tells if the translations are ready.
     * however, if they are already loaded it's synchronous
     */
    await this.translate.getTranslation(locale).toPromise()
    this.translate.setDefaultLang(locale)
    return locale
  }

  private async loadAngularLocaleData(angularLocale: string): Promise<string> {
    return this.localeLoaderService.load(angularLocale).toPromise()
  }
}
