/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { Selector } from '@ngxs/store'
import { ConfirmationCodeStatus } from '../../enums/confirmation-code-status'
import { ERROR_KEY } from '../../pages/error-page/error-page-data'
import { ConfirmationStep } from '../confirmation-step.enum'
import { ConfirmationStateModel } from './confirmation-state.model'
import { ConfirmationState } from './confirmation.state'
import { TransactionType } from '../../enums/transaction-type'
import { ConfirmationType } from '../../enums/confirmation-type'

export class ConfirmationSelectors {
  @Selector([ConfirmationState])
  static codeStatus(state: ConfirmationStateModel): ConfirmationCodeStatus {
    return state.codeStatus
  }

  @Selector([ConfirmationState])
  static confirmationLoading(state: ConfirmationStateModel): boolean {
    return state.confirmationLoading
  }

  @Selector([ConfirmationState])
  static otpRequested(state: ConfirmationStateModel): boolean {
    return state.otpRequested
  }

  @Selector([ConfirmationState])
  static otpRequestBlocked(state: ConfirmationStateModel): boolean {
    return state.otpRequestBlocked
  }

  @Selector([ConfirmationState])
  static spinRequested(state: ConfirmationStateModel): boolean {
    return state.spinRequested
  }

  @Selector([ConfirmationState])
  static connectSuccess(state: ConfirmationStateModel): boolean {
    return state.connectSuccess
  }

  @Selector([ConfirmationState])
  static currentStep(state: ConfirmationStateModel): ConfirmationStep {
    return state.step
  }

  @Selector([ConfirmationState])
  static errorType(state: ConfirmationStateModel): ERROR_KEY | null {
    return state.error_key
  }

  @Selector([ConfirmationState])
  static vehicleBaseModel(state: ConfirmationStateModel): string | null {
    return state.baseModel
  }

  @Selector([ConfirmationState])
  static nonConnectSuccess(state: ConfirmationStateModel): boolean {
    return state.nonConnectSuccess
  }

  @Selector([ConfirmationState])
  static transactionType(state: ConfirmationStateModel): TransactionType {
    return state.transactionType
  }

  @Selector([ConfirmationState])
  static type(state: ConfirmationStateModel): ConfirmationType | null {
    return state.type
  }

  @Selector([ConfirmationState])
  static success(state: ConfirmationStateModel): boolean {
    return state.success
  }

  @Selector([ConfirmationState])
  static confirmationCode(state: ConfirmationStateModel): string {
    return state.confirmationCode
  }
}
