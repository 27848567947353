<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
  -->
<p-heading tag="h1" class="text-balance mt-static_l xs:mt-l">{{ 'signup.createSpinStep.headline' | translate }}</p-heading>
<div class="w-full" *ngIf="transactionType$ | async as transactionType">
  <p-text size="{base: 'small', m: 'medium' }" class="mt-static_m s:mt-static_l text-balance" [innerText]="'signup.createSpinStep.subline' | translate"></p-text>
  <p-text size="{base: 'small', m: 'medium' }" class="mt-static_l s:mt-static_xl text-balance" [innerText]="'signup.createSpinStep.subline2' | translate"></p-text>

  <form [formGroup]="formGroup" class="mt-static_l" data-e2e="confirmation-spin-form">
    <div class="mt-static_xs">
      <p-pin-code
        [label]="'signup.createSpinStep.formLabel.spin' | translate"
        [name]="'SPIN'"
        [length]="spinLength"
        [type]="spinCodeType"
        (update)="submitSpinAndTrack($event)"
        [state]="spinCodeState"
        data-e2e="spin"
        #spinCode
      ></p-pin-code>
    </div>
    <div class="mt-static_l">
      <p-pin-code
        [label]="'signup.createSpinStep.formLabel.spinConfirmation' | translate"
        [name]="'REPEAT'"
        [length]="spinLength"
        [type]="spinCodeType"
        (update)="submitSpinRepeatAndTrack($event)"
        [state]="spinCodeRepeatState"
        [message]="spinCodeMessage | translate"
        data-e2e="spin-repeat"
        #spinRepeatCode
      ></p-pin-code>
    </div>
    <p-button
      (click)="submitSpinForm()"
      class="mt-static_l w-full xs:w-auto"
      variant="primary"
      data-e2e="spin-submit-button"
      [disabled]="disableSubmitButtonAndShowLoading"
      [loading]="disableSubmitButtonAndShowLoading"
      [myprofileSignupUiTrack]="{
        clickElementType: 'button',
        clickElementId: 'call_to_action',
        clickElementName: 'confirm_spin',
        pageName: currentPageName,
        eventAction: 'PAGMyPorsche_SignUpSetsPin_Click'
      }"
    >
      <ng-container *ngIf="isLastStep(currentStep)">
        {{ lastStepLabel | translate }}
      </ng-container>
      <ng-container *ngIf="!isLastStep(currentStep)">
        {{ 'signup.createSpinStep.button.label' | translate }}
      </ng-container>
    </p-button>
  </form>
</div>
