/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { Inject, Injectable } from '@angular/core'
import { PidxDeviceDetectionService } from '../pidx-device-detection/pidx-device-detection.service'
import { WINDOW } from '../utils'
import { HttpErrorResponse } from '@angular/common/http'
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router'
import { SignupService } from '../signup/signup.service'
import { UntypedFormGroup } from '@angular/forms'
import {
  ExtendedClickData,
  ExtendedContext,
  ExtendedIds,
  ExtendedVisitor,
  GTMConfig,
} from './pidx-google-tag-manager-integration.interfaces'
import { OneGaTrackHandler } from './one-ga-track-handler'
import { OneGaManager } from './one-ga-manager'
import { ConfigService } from '../config/config.service'
import { TransactionType } from '../../enums/transaction-type'
import { environment } from '../../../environments/environment'
import { OneGaData, PageExperience, Process } from './one-ga-types'

const EVENT_ACTION_PREFIX = 'PAGMyPorsche'
const PAGELOAD_EVENT_ACTION_SUFFIX = 'Pageload'
const PAGENAME_PREFIX = 'sign_up'

const LOAD_EVENT_PAGE_NAMES = {
  register: 'SignUpRegister',
  register_success: 'SignUpVerifyRequest',
  identityrequest: 'SignUpIdentityRequest',
  identityverification: 'SignUpIdentityVerification',
  thank_you_forward_connect: 'SignUpThankYou',
  thank_you_forward_myporsche: 'SignUpThankYou',
  error: 'Error',
  confirm: 'SignUpSetPW',
  confirm_success: 'SignUpThankYou',
  setspin: 'SignUpSetSpin',
}

const DEVICES = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
  TABLET: 'tablet',
}

@Injectable({
  providedIn: 'root',
})
export class PidxGoogleTagManagerIntegrationService {
  private gtmEnabled: boolean
  private pagData: any

  private config: GTMConfig
  private context: ExtendedContext
  private visitor: ExtendedVisitor
  private page: PageExperience

  private formValidationStore = {}

  private isRegisterWithMobileActive: boolean

  constructor(
    private _pidxDeviceDetectionService: PidxDeviceDetectionService,
    @Inject(WINDOW) private _window: Window,
    private _route: ActivatedRoute,
    private _signupService: SignupService,
    private _oneGaTrackHandler: OneGaTrackHandler,
    private _oneGaManager: OneGaManager,
    private _configService: ConfigService,
  ) {}

  public async init(config: GTMConfig, enabled = false) {
    this.isRegisterWithMobileActive = environment.features.isRegisterWithMobileNumberOnly
    this.config = config
    this.gtmEnabled = enabled

    if (!this.gtmEnabled) {
      return
    }

    const language = this.config.language.split('_')

    this.context = {
      applicationId: this.config.applicationId,
      environment: this.config.environment,
      country: this.config.country.toUpperCase(),
      language: language[0],
      server: this.config.server,
    }

    this._oneGaManager.init(this._configService.getGTMContainerId(), {
      context: this.config,
    })

    this.visitor = this.getVisitorInfo()
    this.updateDeviceAndRoute()
    const process = this.getProcessForGTM()
    const ids: ExtendedIds = this.getExtendedIds()

    this.pagData = {
      pageExperience: this.page,
      visitor: this.visitor,
      ids: ids,
      process: process,
      car: this._signupService.car,
      context: {
        ...this.context,
        timestamp: this.getTimestamp(),
      },
    }
  }

  public async triggerPageLoad(overwriteOptions?: any, error?: { message: string; statusCode: string }) {
    if (this.isRegisterWithMobileActive === undefined) {
      this.isRegisterWithMobileActive = environment.features.isRegisterWithMobileNumberOnly
    }
    this.updateDeviceAndRoute()

    if (overwriteOptions?.pageName) {
      this.page.pageName = !overwriteOptions.pageName.startsWith(PAGENAME_PREFIX)
        ? `${PAGENAME_PREFIX}/${overwriteOptions.pageName}`
        : overwriteOptions.pageName
    }

    let eventAction = `${EVENT_ACTION_PREFIX}_${LOAD_EVENT_PAGE_NAMES[this.page.pageName.split('/')[1]]}_${PAGELOAD_EVENT_ACTION_SUFFIX}`

    if (overwriteOptions?.eventAction) {
      eventAction = overwriteOptions.eventAction
    }
    const process = this.getProcessForGTM()
    const ids: ExtendedIds = this.getExtendedIds()

    const gtmData: OneGaData = {
      pageExperience: this.page,
      visitor: this.getVisitorInfo(),
      ids: ids,
      process: process,
      car: this._signupService.car,
      context: {
        ...this.context,
        timestamp: this.getTimestamp(),
        eventAction,
      },
    }

    if (error) {
      gtmData.pageExperience = {
        ...gtmData.pageExperience,
        errorCode: error.statusCode,
        errorMessage: error.message,
        errorReferringUrl: this.page.pageId,
      }
    }
    this._oneGaTrackHandler.track(gtmData)
  }

  public triggerClickTracking(componentClickData: ExtendedClickData, error?: HttpErrorResponse) {
    if (!this.gtmEnabled) {
      return
    }

    this.updateDeviceAndRoute()

    if (componentClickData.pageName) {
      this.page.pageName = !componentClickData.pageName.startsWith(PAGENAME_PREFIX)
        ? `${PAGENAME_PREFIX}/${componentClickData.pageName}`
        : componentClickData.pageName
    }

    let eventAction = `${EVENT_ACTION_PREFIX}_${LOAD_EVENT_PAGE_NAMES[this.page.pageName.split('/')[1]]}_${PAGELOAD_EVENT_ACTION_SUFFIX}`

    if (componentClickData.eventAction) {
      eventAction = componentClickData.eventAction
    }
    const process = this.getProcessForGTM()
    const ids: ExtendedIds = this.getExtendedIds()

    const gtmData: OneGaData = {
      pageExperience: this.page,
      visitor: this.getVisitorInfo(),
      ids: ids,
      process: process,
      car: this._signupService.car,
      context: {
        ...this.context,
        timestamp: this.getTimestamp(),
        eventAction,
      },
      componentClick: {
        ...componentClickData,
      },
    }

    if (error) {
      gtmData.pageExperience = {
        ...gtmData.pageExperience,
        errorCode: error.status.toString(),
        errorMessage: error.message,
        errorReferringUrl: this.page.pageId,
      }
    }
    this._oneGaTrackHandler.track(gtmData)
  }

  public triggerLoadTracking(overwriteOptions?: any, error?: HttpErrorResponse) {
    if (!this.gtmEnabled) {
      return
    }

    this.updateDeviceAndRoute()

    if (overwriteOptions?.pageName) {
      this.page.pageName = !overwriteOptions.pageName.startsWith(PAGENAME_PREFIX)
        ? `${PAGENAME_PREFIX}/${overwriteOptions.pageName}`
        : overwriteOptions.pageName
    }

    let eventAction = `${EVENT_ACTION_PREFIX}_${LOAD_EVENT_PAGE_NAMES[this.page.pageName.split('/')[1]]}_${PAGELOAD_EVENT_ACTION_SUFFIX}`

    if (overwriteOptions?.eventAction) {
      eventAction = overwriteOptions.eventAction
    }
    const process = this.getProcessForGTM()
    const ids: ExtendedIds = this.getExtendedIds()

    const gtmData: OneGaData = {
      pageExperience: this.page,
      visitor: this.getVisitorInfo(),
      ids: ids,
      process: process,
      car: this._signupService.car,
      context: {
        ...this.context,
        timestamp: this.getTimestamp(),
        eventAction,
      },
    }

    if (error) {
      gtmData.pageExperience = {
        ...gtmData.pageExperience,
        errorCode: error.status.toString(),
        errorMessage: error.message,
        errorReferringUrl: this.page.pageId,
      }
    }
    this._oneGaTrackHandler.track(gtmData)
  }

  public trackInvalidInputEntry(formName: string, overwriteOptions?: any) {
    if (!this.gtmEnabled) {
      return
    }

    this.updateDeviceAndRoute()

    if (overwriteOptions?.pageName) {
      this.page.pageName = !overwriteOptions.pageName.startsWith(PAGENAME_PREFIX)
        ? `${PAGENAME_PREFIX}/${overwriteOptions.pageName}`
        : overwriteOptions.pageName
    }

    let eventAction = 'PAGMyPorsche_SignUpFormError_Load'

    if (overwriteOptions?.eventAction) {
      eventAction = overwriteOptions.eventAction
    }

    const componentFormErrors = [...this.formValidationStore[formName].values()].map((fieldName, index) => {
      return { fieldName: fieldName, fieldId: index }
    })
    const process = this.getProcessForGTM()
    const ids: ExtendedIds = this.getExtendedIds()

    const gtmData: OneGaData = {
      pageExperience: this.page,
      visitor: this.getVisitorInfo(),
      car: this._signupService.car,
      ids: ids,
      process: process,
      context: {
        ...this.context,
        timestamp: this.getTimestamp(),
        eventAction: eventAction,
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore // TODO: Remove once interfaces of angular common are aligned with the latest OneGA spec
      componentForm: {
        errors: componentFormErrors,
      },
    }
    this._oneGaTrackHandler.track(gtmData)
  }

  public trackFormError(form: UntypedFormGroup, overwriteOptions?: any) {
    if (!this.gtmEnabled) {
      return
    }

    this.updateDeviceAndRoute()

    if (overwriteOptions?.pageName) {
      this.page.pageName = !overwriteOptions.pageName.startsWith(PAGENAME_PREFIX)
        ? `${PAGENAME_PREFIX}/${overwriteOptions.pageName}`
        : overwriteOptions.pageName
    }

    let eventAction = 'PAGMyPorsche_SignUpFormError_Load'

    if (overwriteOptions?.eventAction) {
      eventAction = overwriteOptions.eventAction
    }
    let componentFormErrors = Object.keys(form.controls).map((fieldName, index) => {
      if (form.controls[fieldName].errors) return { fieldName: fieldName, fieldId: index }
    })

    componentFormErrors = componentFormErrors.filter((value) => value !== undefined)
    const process = this.getProcessForGTM()
    const ids: ExtendedIds = this.getExtendedIds()

    const gtmData: OneGaData = {
      pageExperience: this.page,
      visitor: this.getVisitorInfo(),
      car: this._signupService.car,
      ids: ids,
      process: process,
      context: {
        ...this.context,
        timestamp: this.getTimestamp(),
        eventAction: eventAction,
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore // TODO: Remove once interfaces of angular common are aligned with the latest OneGA spec
      componentForm: {
        errors: componentFormErrors,
      },
    }
    if (componentFormErrors.length > 0) {
      this._oneGaTrackHandler.track(gtmData)
    }
  }

  public setupValidationError(formName: string) {
    this.formValidationStore[formName] = new Set()
  }

  public clearValidationErrors(formName: string) {
    delete this.formValidationStore[formName]
  }

  public hasValidationErrors(formName: string): boolean {
    if (!formName || typeof this.formValidationStore[formName] === 'undefined') {
      return false
    }

    return !!this.formValidationStore[formName].size
  }

  public addValidationError(formName: string, formControlName: string) {
    if (!this.formValidationStore[formName].has(formControlName)) {
      this.formValidationStore[formName].add(formControlName)
    }
  }

  public removeValidationError(formName: string, formControlName: string) {
    this.formValidationStore[formName].delete(formControlName)
  }

  public cleanUpPageUrl(url: string): string {
    // To prevent sending sensitive data to the google tag manager we remove the hash and the search.
    // In case any of the hashes or search params is needed this implementation needs to be adjusted accordingly
    const urlObj = new URL(url)
    urlObj.hash = ''
    urlObj.search = ''

    // Obfuscate params that are same name as in data?.analytics?.obfuscate
    const childRoute = this._route.snapshot.firstChild?.firstChild?.firstChild
    const parametersToObfuscate = childRoute?.data['analytics']?.obfuscate as string[]
    if (parametersToObfuscate) {
      parametersToObfuscate.forEach((paramToObfuscate) => {
        const paramToReplace = childRoute?.params[paramToObfuscate]
        urlObj.pathname = paramToReplace ? urlObj.pathname.replace(paramToReplace, '*'.repeat(paramToReplace.length)) : urlObj.pathname
      })
    }
    return urlObj.href
  }

  public getVisitorInfo(): ExtendedVisitor {
    return {
      loginStatus: this.getLoginStatus(),
      details: this.getVisitorDetailsForGTM(),
      userType: this._signupService.accountType,
      deviceManufacturer: this._window.navigator.vendor,
      useragent: this._window.navigator.userAgent,
      deviceType: this._pidxDeviceDetectionService.isMobileDevice()
        ? this._pidxDeviceDetectionService.isMobilePhoneDevice()
          ? DEVICES.MOBILE
          : DEVICES.TABLET
        : DEVICES.DESKTOP,
    }
  }

  public updateDeviceAndRoute() {
    this.visitor = this.getUpdatedDeviceData()
    this.page = this.getUpdatedRouteData()
  }

  public getUpdatedDeviceData() {
    return {
      ...this.visitor,
      deviceBrowserHeight: this._window.innerHeight.toString(),
      deviceBrowserWidth: this._window.innerWidth.toString(),
      deviceBrowserOrientation: this._window.matchMedia('(orientation: portrait)').matches ? 'p' : 'l',
      userType: this._signupService.accountType,
      details: this.getVisitorDetailsForGTM(),
    }
  }

  public getUpdatedRouteData() {
    const pathName = new URL(this._window.location.href).pathname
    const queryParam = this._route.snapshot?.queryParamMap.get('errorKey')
    const pageName = this.getPageNameFromPathName(pathName)

    let updatedData: PageExperience = {
      pageId: this.cleanUpPageUrl(this._window.location.href),
      pageName: `${PAGENAME_PREFIX}/${pageName}`,
    }
    if (pageName === 'error') {
      const errorDetails = this.getErrorPageDetails(queryParam)
      updatedData = {
        ...updatedData,
        errorCode: errorDetails.errorCode,
        errorMessage: errorDetails.errorMessage,
        errorReferringUrl: errorDetails.errorReferringUrl,
      }
    }
    return updatedData
  }

  public getTimestamp() {
    const pad = (num) => {
      let s = num + ''
      while (s.length < 2) {
        s = '0' + s
      }
      return s
    }

    const date = new Date()
    const offset = date.getTimezoneOffset() * -1

    const offsetSign = offset >= 0 ? '+' : '-'
    const formattedOffset = `${pad(Math.floor(offset / 60))}:${pad(offset % 60)}`

    return `${date.toISOString().substring(0, 19)}${offsetSign}${formattedOffset}`
  }

  private getPageNameFromPathName(pathName: string): string {
    const deepestChild = this.findLastChild(this._route?.snapshot)
    const pageLoadObj = deepestChild?.data?.analytics?.pageLoad

    if (pageLoadObj) {
      return pageLoadObj.pageName
    }

    if (pathName.indexOf('register') !== -1) {
      if (pathName.indexOf('success') !== -1) {
        return 'register_success'
      }
      return 'register'
    } else if (pathName.indexOf('confirm') !== -1) {
      if (pathName.indexOf('success') !== -1) {
        return 'confirm_success'
      }
      return 'confirm'
    } else if (pathName.indexOf('error') !== -1) {
      return 'error'
    }
    return 'pathNameDoesNotMatchAnyGivenPages'
  }

  private findLastChild(snapshot: ActivatedRouteSnapshot | undefined) {
    if (snapshot.firstChild === null) {
      return snapshot
    }

    return this.findLastChild(snapshot.firstChild)
  }

  private getErrorPageDetails(errorCode: string) {
    let errorMessageDetail = ''
    let errorReferringUrl = ''
    switch (errorCode) {
      case 'GENERIC':
        errorMessageDetail = 'General Issue'
        errorReferringUrl = 'not_applicable'
        break
      case 'BUSINESS_EXCEPTION_TRANSACTION_AUTHENTICATION_OPTION_IS_EXPIRED':
        errorMessageDetail = 'Organization User clicked on the invitation link after 14 days'
        errorReferringUrl = 'link_in_email'
        break
      case 'BUSINESS_EXCEPTION_TRANSACTION_AUTHENTICATION_OPTION_IS_EXPIRED_PROSPECT':
        errorMessageDetail = 'Prospect User clicked on the invitation link after 14 days'
        errorReferringUrl = 'link_in_email'
        break
      case 'BUSINESS_EXCEPTION_TRANSACTION_ALREADY_CONFIRMED_OR_ABORTED':
        errorMessageDetail = 'Porsche ID was already confirmed'
        errorReferringUrl = 'link_in_email'
        break
    }
    return {
      errorCode: errorCode,
      errorMessage: errorMessageDetail,
      errorReferringUrl: errorReferringUrl,
    }
  }

  private getVisitorDetailsForGTM(): { fieldId: string; fieldName: string }[] {
    const details: { fieldId: string; fieldName: string }[] = []
    if (this._signupService.car) {
      this._signupService.car.isConnectable
        ? details.push({ fieldId: '01', fieldName: 'Connectable' })
        : details.push({ fieldId: '01', fieldName: 'nonConnectable' })
    } else {
      details.push({ fieldId: '01', fieldName: undefined })
    }
    //because we don't know residualTime/NoResidualTime
    details.push({ fieldId: '02', fieldName: undefined })
    this._signupService.transactionType === TransactionType.CONNECT_OWNER ||
    this._signupService.transactionType === TransactionType.PROSPECT ||
    this._signupService.transactionType === TransactionType.NON_CONNECT_OWNER ||
    this._signupService.transactionType === TransactionType.SECONDARY
      ? details.push({ fieldId: '03', fieldName: 'NoAccount' })
      : details.push({ fieldId: '03', fieldName: 'existingAccount' })
    this._signupService.isLeanActivationCountry
      ? details.push({ fieldId: '04', fieldName: 'Lean' })
      : details.push({ fieldId: '04', fieldName: 'nonLean' })
    this._signupService.needsSpin
      ? details.push({ fieldId: '05', fieldName: 'sPin' })
      : details.push({ fieldId: '05', fieldName: 'NosPin' })
    return details
  }

  private getProcessForGTM(): Process | undefined {
    const process: Process = {}
    let subProcess = ''
    process.processType = 'connect_activation'
    switch (this._signupService.transactionType) {
      case 'ADD_CONNECT_VEHICLE':
      case 'ADD_NON_CONNECT_VEHICLE':
        process.processName = 'add_vehicle'
        break
      case 'SIMPLIFIED_ADD_CONNECT_VEHICLE':
      case 'SIMPLIFIED_ADD_NON_CONNECT_VEHICLE':
        process.processName = 'simplified_add_vehicle'
        break
      case 'SECONDARY':
      case 'SECONDARY_VEHICLE':
        process.processName = 'add_secondary_user'
        break
      case 'CONNECT_OWNER':
      case 'NON_CONNECT_OWNER':
        process.processName = 'owner_signup'
        break
      case 'PROSPECT':
        process.processName = 'prospect_signup'
        process.processType = 'prospect'
        break
      case 'TRACK_YOUR_DREAM':
        process.processName = 'track_your_dream'
        process.processType = 'track_your_dream'
        break
    }
    if (this._signupService.car) {
      this._signupService.car.isConnectable ? (subProcess = subProcess + 'Connectable') : (subProcess = subProcess + 'nonConnectable')
    } else {
      subProcess = subProcess + 'undefined'
    }
    //cause we don't know residualTime/NoResidualTime
    subProcess = subProcess + '|undefined'
    this._signupService.transactionType === TransactionType.CONNECT_OWNER ||
    this._signupService.transactionType === TransactionType.PROSPECT ||
    this._signupService.transactionType === TransactionType.NON_CONNECT_OWNER ||
    this._signupService.transactionType === TransactionType.TRACK_YOUR_DREAM ||
    this._signupService.transactionType === TransactionType.SECONDARY
      ? (subProcess = subProcess + '|NoAccount')
      : (subProcess = subProcess + '|existingAccount')
    this._signupService.isLeanActivationCountry ? (subProcess = subProcess + '|Lean') : (subProcess = subProcess + '|nonLean')
    this._signupService.needsSpin ? (subProcess = subProcess + '|sPin') : (subProcess = subProcess + '|NosPin')
    subProcess = this.isRegisterWithMobileActive ? subProcess + '|mobile' : subProcess + '|email'
    if (this.page.pageName.includes('register')) {
      process.processName = 'prospect_signup'
      process.processType = 'prospect'
      process.processSubtype = this.isRegisterWithMobileActive ? 'mobile' : 'email'
    } else {
      process.processSubtype = subProcess
    }
    return process
  }

  private getLoginStatus(): boolean {
    return !(
      this._signupService.transactionType === TransactionType.CONNECT_OWNER ||
      this._signupService.transactionType === TransactionType.PROSPECT ||
      this._signupService.transactionType === TransactionType.NON_CONNECT_OWNER ||
      this._signupService.transactionType === TransactionType.TRACK_YOUR_DREAM
    )
  }

  private getExtendedIds(): ExtendedIds | undefined {
    const ids: ExtendedIds = {}
    if (this._signupService.hashedCiamId) {
      ids.ciamId = this._signupService.hashedCiamId
    }
    if (this._signupService.hashedPorscheId) {
      ids.userId = this._signupService.hashedPorscheId
    }
    return Object.keys(ids).length === 0 ? undefined : ids
  }
}
