/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { getOneGaManagerData, getPagDataLayer, getTrackingLayer } from './data-handler'
import { addScript } from './add-script'
import { Injectable } from '@angular/core'
import * as deepmerge from 'deepmerge'
import { OneGaData } from './one-ga-types'

@Injectable({
  providedIn: 'root',
})
export class OneGaManager {
  public init(dataLayerId: string, appDefaultOneGaData?: OneGaData) {
    const existingEvents = getTrackingLayer().slice(0)
    getTrackingLayer().push = function (...items: OneGaData[]) {
      const array = Array.prototype.push.call(this, ...items)

      trackToPagDataLayer(...items)
      return array
    }

    this.updateDefaultProperties(appDefaultOneGaData ? appDefaultOneGaData : {})
    trackToPagDataLayer(...existingEvents)
    addScript.addGtmScript(dataLayerId)
  }

  public updateDefaultProperties(propertiesToMerge: OneGaData) {
    getOneGaManagerData().applicationDefault = deepmerge({ ...getOneGaManagerData().applicationDefault }, propertiesToMerge)
  }
}

function trackToPagDataLayer(...gtmData: OneGaData[]) {
  gtmData.forEach((event) => {
    const trackingData = deepmerge({ ...getOneGaManagerData().applicationDefault }, { ...event })
    getPagDataLayer().push(trackingData)
  })
}
