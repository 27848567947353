<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
  -->

<div [formGroup]="group">
  <p-text-field-wrapper
    [label]="model.label"
    [state]="!!showErrorMessages ? 'error' : 'none'"
    [message]="errorMessages[0]"
    [description]="model.hint"
    [id]="model.id + '-textFieldWrapper'"
    [showCounter]="false"
  >
    <input
      [ngClass]="[getClass('element', 'control'), getClass('grid', 'control')]"
      [attr.autocomplete]="model.autoComplete"
      [attr.accept]="model.accept"
      [attr.max]="model.max"
      [attr.min]="model.min"
      [attr.multiple]="model.multiple"
      [attr.step]="model.step"
      [autoFocus]="model.autoFocus"
      [dynamicList]="model.listId"
      [formControlName]="model.id"
      [id]="id"
      [maxlength]="model.maxLength"
      [minlength]="model.minLength"
      [name]="model.name"
      [pattern]="model.pattern"
      [readonly]="model.readOnly"
      [required]="model.required"
      [attr.spellcheck]="model.spellCheck"
      [tabindex]="model.tabIndex"
      [type]="model.inputType"
      (blur)="onBlur($event)"
      (change)="onChange($event)"
      (focus)="onFocus($event)"
    />
  </p-text-field-wrapper>

  <ng-template #strengthMeterViewContainerRef></ng-template>
</div>
