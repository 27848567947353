/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component } from '@angular/core'

@Component({
    selector: 'myprofile-signup-ui-layout-success',
    templateUrl: './layout-success.component.html',
    styleUrls: ['./layout-success.component.scss'],
    standalone: false
})
export class LayoutSuccessComponent {
}
