/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */
import { Component } from '@angular/core'
import {NavigationComponent} from "../../components/navigation/navigation.component";
import {RouterOutlet} from "@angular/router";
import {NgIf} from "@angular/common";

@Component({
    selector: 'myprofile-signup-ui-layout-maintenance',
    templateUrl: './layout-maintenance.component.html',
    imports: [
        NavigationComponent,
        RouterOutlet,
        NgIf
    ]
})
export class LayoutMaintenanceComponent {}
