/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import {Component, EventEmitter, Input, Output} from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { BaseFormControlComponent } from '../base-form-control.component'
import { DynamicFormLayout, DynamicFormLayoutService } from '../../../service/dynamic-form-layout.service'
import { DynamicFormControlLayout } from '../../../model/misc/dynamic-form-control-layout.model'
import { DynamicCheckboxModel } from '../../../model/checkbox/dynamic-checkbox.model'
import { DynamicFormValidationService } from '../../../service/dynamic-form-validation.service'

@Component({
    selector: 'dynamic-basic-checkbox',
    templateUrl: './dynamic-basic-checkbox.component.html',
    standalone: false
})
export class DynamicBasicCheckboxComponent extends BaseFormControlComponent {
  get controlState(): 'error' | 'none' {
    return this.isInvalid && this.control.touched ? 'error' : 'none'
  }

  @Input() formLayout: DynamicFormLayout
  @Input() group: UntypedFormGroup
  @Input() layout: DynamicFormControlLayout
  @Input() model: DynamicCheckboxModel

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur: EventEmitter<any> = new EventEmitter()
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: EventEmitter<any> = new EventEmitter()
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() focus: EventEmitter<any> = new EventEmitter()

  public onChange($event: any) {
    if ($event instanceof Event) {
      $event.stopPropagation()
    }

    const control = this.group.get(this.model.id)
    if (!!this.model.openURLOnClick && control.value === true) {
      window.open(this.model.openURLOnClick, '_blank')
    }

    this.change.emit($event)
  }

  constructor(
    public layoutService: DynamicFormLayoutService,
    public validationService: DynamicFormValidationService,
  ) {
    super(layoutService, validationService)
  }
}
