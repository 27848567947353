/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
import { defineCustomElements as definePorscheNavigation } from '@pcom-navigate/header/loader'

if (environment.production) {
  enableProdMode()
}
const URI_REGEX = /^\/(?<country>\w{2})\/(?<locale>\w{2}(([-_])\w{2})?)(?<rest>\/.*)?$/
const URI_OAUTH_PKCE_CODE = /^\/auth\/callback.*$/
const COM_HOST_REGEX = /^signup\.porsche(?:-preview)?\.com$/
const CN_HOST_REGEX = /^signup\.porsche(?:-preview)?\.cn$/
const CN_REQUEST_REGEX = /^\/((cn|CN|hk|HK)\/.*)$/
const RUSSIA_ERROR_PAGE = 'http://www.porsche.ru'
const LOCALISATION_API = document.location.href.includes('.cn') ? environment.urls.localisationCN : environment.urls.localisation
const PATH = 'country-locale-map/' + (environment.production ? 'RELEASE' : 'LATEST') + '/country-locale-map.json'
let SUPPORTED_COUNTRY_LOCALE_COMBIS: Map<string, { default: string; locales: string[] }> = new Map<
  string,
  { default: string; locales: string[] }
>()
async function fetchSupportedCoutryLocaleCombis() {
  try {
    const response = await fetch(`${LOCALISATION_API}${PATH}`)
    SUPPORTED_COUNTRY_LOCALE_COMBIS = await response.json()
    const redirected = await checkRequestedUrl()

    // If we start bootstrapping while redirecting, we face some issues with some processes and calls
    if (!redirected) {
      platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch((err) => console.error(err))
    }
  } catch (error) {
    console.error(error)
  }
}

injectDesignSystemCdn()
fetchSupportedCoutryLocaleCombis()
definePorscheNavigation(window)

async function checkRequestedUrl() {
  const host = document.location.host
  const uri = document.location.pathname
  const queryParams = document.URL.split('?').length > 1 ? document.URL.split('?')[1] : ''
  const matches = uri.match(URI_REGEX)
  const oAuthFlowMatches = uri.match(URI_OAUTH_PKCE_CODE) !== null

  if (oAuthFlowMatches) {
    return false
  }
  if (!matches || SUPPORTED_COUNTRY_LOCALE_COMBIS.size > 0) {
    setLocation(undefined, undefined, true)
    return true
  }
  const country = matches.groups.country
  let locale = matches.groups.locale
  const rest = matches.groups.rest
  //sometimes we get the country in uppercase and we have to deal with
  const countryIsUppercase = country === country.toUpperCase()

  let isTrimmedLocale = false
  if (_isCountrySupported(country)) {
    // special case for NULU (locale params are trimmed to language prefix only)
    if (locale.length === 2) {
      isTrimmedLocale = true
      const candidates = _getSupportedLocales(country)?.filter((candidate) => candidate.startsWith(locale.toLowerCase()))
      locale = candidates.length ? candidates[0] : _getDefaultLocale(country)
    }

    const isCountryLocaleSupported = _isCountryLocaleCombinationSupported(country, locale)
    const isUrlParamMissing = rest === undefined
    if (_isComHost(host) && _isCnRequest(uri)) {
      const replaceUrl = document.location.href
      document.location.replace(replaceUrl.replace('.com', '.cn'))
      return true
    }
    if (_isCNHost(host) && !_isCnRequest(uri)) {
      const replaceUrl = document.location.href
      document.location.replace(replaceUrl.replace('.cn', '.com'))
      return true
    }
    if (isCountryLocaleSupported && isUrlParamMissing) {
      setLocation(country, locale, true)
      return true
    } else if (isCountryLocaleSupported) {
      if (isTrimmedLocale || countryIsUppercase) {
        setLocation(country, locale)
        return true
      }
      return false
    } else if (_isCountryLocaleCombinationPartiallySupported(country, locale)) {
      const [normalizedCountry, normalizedLocale] = _getNormalizedCountryLocales(country, locale)
      setLocation(normalizedCountry, normalizedLocale)
      return true
    } else {
      locale = _getDefaultLocale(country)
      setLocation(country, locale)
      return true
    }
  } else if (country === 'ru') {
    // Russia is not allowed and will be redirected to error landing page
    document.location.href = RUSSIA_ERROR_PAGE
    return true
  } else {
    // Redirect to default country/locale
    setLocation()
    return true
  }
}

/**
 * Returns the default locale for the specified country e.g. `de -> 'de_DE'`
 */
function _getDefaultLocale(country: string) {
  const countryExists = _isCountrySupported(country)
  return countryExists ? SUPPORTED_COUNTRY_LOCALE_COMBIS[country.toLowerCase()].default : null
}

/**
 * Returns whether the supplied country and locales are valid in terms of the possible combinations without
 * taking upper- and lower-casing into consideration.
 */
function _isCountryLocaleCombinationPartiallySupported(country: string, locale: string) {
  const [normalizedCountry, normalizedLocale] = _getNormalizedCountryLocales(country, locale)
  const countryExists = _isCountrySupported(normalizedCountry)
  return countryExists ? _getSupportedLocales(normalizedCountry).some((supportedLocale) => supportedLocale === normalizedLocale) : false
}

/**
 * Returns whether the supplied country is supported in our application e.g. `fr -> true`
 */
function _isCountrySupported(country: string) {
  return SUPPORTED_COUNTRY_LOCALE_COMBIS[country.toLowerCase()]
}

function _getNormalizedCountryLocales(country: string, locale: string) {
  const [l1, l2] = locale.includes('-') ? locale.split('-') : locale.split('_')
  const normalizedLocale = `${l1.toLowerCase()}_${l2?.toUpperCase()}`
  const normalizedCountry = country?.toLowerCase()
  return [normalizedCountry, normalizedLocale]
}

/**
 * Returns all supported locales for one specified country e.g. `de -> ['de_DE', 'de_AT']`
 */
function _getSupportedLocales(country: string) {
  const countryExists = _isCountrySupported(country)
  return countryExists ? SUPPORTED_COUNTRY_LOCALE_COMBIS[country.toLowerCase()].locales : null
}

/**
 * Returns whether the supplied country and locale is a valid combination e.g. `de / de_DE -> true`
 */
function _isCountryLocaleCombinationSupported(country: string, locale: string) {
  const countryExists = _isCountrySupported(country.toLowerCase())
  return countryExists ? _getSupportedLocales(country.toLowerCase()).some((supportedLocale) => supportedLocale === locale) : false
}

function _isComHost(host: string) {
  const match = host.match(COM_HOST_REGEX)
  return match?.length
}

function _isCNHost(host: string) {
  const match = host.match(CN_HOST_REGEX)
  return match?.length
}

function _isCnRequest(uri: string) {
  if (!uri) return false
  const match = uri.match(CN_REQUEST_REGEX)
  return match?.length
}

function injectDesignSystemCdn() {
  window.location.href.includes('.cn')
    ? ((window as any).PORSCHE_DESIGN_SYSTEM_CDN = 'cn')
    : ((window as any).PORSCHE_DESIGN_SYSTEM_CDN = 'auto')
}

function setLocation(country?: string, locale?: string, standardLocation = false) {
  const cnDomainRequest = window.location.href.includes('.cn')
  const c = country ? country.toLowerCase() : cnDomainRequest ? 'cn' : 'gb'
  const l = locale ? locale : cnDomainRequest ? 'zh_CN' : 'en_GB'
  const path = document.location.pathname.split('/')
  path[1] = c
  path[2] = l
  standardLocation ? (document.location.pathname = `${c}/${l}/register`) : (document.location.pathname = path.join('/'))
}
