/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { Subscription } from 'rxjs'
import { DynamicFormControlEvent, DynamicFormControlEventType, isDynamicFormControlEvent } from './dynamic-form-control-event'
import { DynamicFormControlModel } from '../model/dynamic-form-control.model'
import { DynamicFormValueControlModel } from '../model/dynamic-form-value-control.model'
import { DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX } from '../model/checkbox/dynamic-checkbox.model'
import {
  DYNAMIC_FORM_CONTROL_INPUT_TYPE_FILE,
  DYNAMIC_FORM_CONTROL_TYPE_INPUT,
  DynamicInputModel,
} from '../model/input/dynamic-input.model'
import {
  DynamicFormControlLayout,
  DynamicFormControlLayoutContext,
  DynamicFormControlLayoutPlace,
} from '../model/misc/dynamic-form-control-layout.model'
import { DynamicFormControl } from './dynamic-form-control.interface'
import { DynamicFormLayout, DynamicFormLayoutService } from '../service/dynamic-form-layout.service'
import { DynamicFormValidationService } from '../service/dynamic-form-validation.service'
import { DynamicFormComponentService } from '../service/dynamic-form-component.service'
import { isString } from '../utils/core.utils'
import { DynamicFormRelationService } from '../service/dynamic-form-relation.service'
import { DynamicBasicCheckboxComponent } from './form-controls/checkbox/dynamic-basic-checkbox.component'
import { DynamicBasicInputComponent } from './form-controls/input/dynamic-basic-input.component'
import { DynamicBasicParagraphComponent } from './form-controls/paragraph/dynamic-basic-paragraph.component'
import { DynamicBasicRadioGroupComponent } from './form-controls/radio-group/dynamic-basic-radio-group.component'
import { DynamicBasicSelectComponent } from './form-controls/select/dynamic-basic-select.component'
import { DYNAMIC_FORM_CONTROL_TYPE_GROUP } from '../model/form-group/dynamic-form-group.model'
import { DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP } from '../model/checkbox/dynamic-checkbox-group.model'
import { DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP } from '../model/radio/dynamic-radio-group.model'
import { DYNAMIC_FORM_CONTROL_TYPE_SELECT } from '../model/select/dynamic-select.model'
import { DynamicFormGroupComponent } from './dynamic-form-group.component'
import { DYNAMIC_FORM_CONTROL_TYPE_PARAGRAPH } from '../model/paragraph/dynamic-paragraph.model'

@Component({
    selector: 'dynamic-form-control',
    templateUrl: './dynamic-form-control-container.component.html',
    standalone: false
})
export class DynamicFormControlContainerComponent implements OnChanges, OnDestroy {
  @HostBinding('class') klass

  @Input() group: UntypedFormGroup
  @Input() hostClass: string[]
  @Input() layout: DynamicFormLayout
  @Input() model: DynamicFormControlModel

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur: EventEmitter<DynamicFormControlEvent> = new EventEmitter<DynamicFormControlEvent>()
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: EventEmitter<DynamicFormControlEvent> = new EventEmitter<DynamicFormControlEvent>()
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() focus: EventEmitter<DynamicFormControlEvent> = new EventEmitter<DynamicFormControlEvent>()
  @Output() metaDataSelectedEvent: EventEmitter<any> = new EventEmitter<any>()

  @ViewChild('componentViewContainer', {
    read: ViewContainerRef,
    static: true,
  })
  componentViewContainerRef: ViewContainerRef

  control: UntypedFormControl

  public componentRef: ComponentRef<DynamicFormControl>
  public componentSubscriptions: Subscription[] = []
  public controlLayout: DynamicFormControlLayout
  public subscriptions: Subscription[] = []

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public componentFactoryResolver: ComponentFactoryResolver,
    public layoutService: DynamicFormLayoutService,
    public validationService: DynamicFormValidationService,
    public componentService: DynamicFormComponentService,
    public relationService: DynamicFormRelationService,
  ) {}

  private _hasFocus = false

  get hasFocus(): boolean {
    return this._hasFocus
  }

  get componentType(): Type<DynamicFormControl> | null {
    return this.basicUIFormControlMapFn(this.model)
  }

  get id(): string {
    return this.layoutService.getElementId(this.model)
  }

  get isInvalid(): boolean {
    return this.control.invalid
  }

  get isValid(): boolean {
    return this.control.valid
  }

  get errorMessages(): string[] {
    return this.validationService.createErrorMessages(this.control, this.model)
  }

  get showErrorMessages(): boolean {
    return this.validationService.showErrorMessages(this.control, this.model, this.hasFocus)
  }

  get hasLabel(): boolean {
    return isString(this.model.label)
  }

  get hasHint(): boolean {
    return isString((this.model as DynamicFormValueControlModel<any>).hint)
  }

  get hint(): string | null {
    return (this.model as DynamicFormValueControlModel<any>).hint || null
  }

  markForCheck(): void {
    this.changeDetectorRef.markForCheck()
    const component = this.componentRef?.instance

    if (component && component instanceof DynamicFormGroupComponent) {
      component.markForCheck()
    }
  }

  basicUIFormControlMapFn(model: DynamicFormControlModel): Type<DynamicFormControl> | null {
    switch (model.type) {
      case DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX:
        return DynamicBasicCheckboxComponent

      case DYNAMIC_FORM_CONTROL_TYPE_GROUP:
      case DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP:
        return DynamicFormGroupComponent

      case DYNAMIC_FORM_CONTROL_TYPE_INPUT:
        return DynamicBasicInputComponent

      case DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP:
        return DynamicBasicRadioGroupComponent

      case DYNAMIC_FORM_CONTROL_TYPE_SELECT:
        return DynamicBasicSelectComponent

      case DYNAMIC_FORM_CONTROL_TYPE_PARAGRAPH:
        return DynamicBasicParagraphComponent

      default:
        return null
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const groupChange = (changes as Pick<SimpleChanges, 'group'>).group
    const layoutChange = (changes as Pick<SimpleChanges, 'layout'>).layout
    const modelChange = (changes as Pick<SimpleChanges, 'model'>).model

    if (layoutChange || modelChange) {
      this.onLayoutOrModelChange()
    }

    if (modelChange) {
      this.onModelChange()
    }

    if (groupChange || modelChange) {
      this.onGroupOrModelChange()
    }

    this.changeDetectorRef.detectChanges()
  }

  ngOnDestroy() {
    this.destroyFormControlComponent()
    this.unsubscribe()
  }

  getClass(context: DynamicFormControlLayoutContext, place: DynamicFormControlLayoutPlace): string {
    return this.layoutService.getClass(this.controlLayout, context, place)
  }

  unsubscribe(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
    this.subscriptions = []
  }

  onControlValueChanges(value: any): void {
    if (this.model instanceof DynamicFormValueControlModel && this.model.value !== value) {
      this.model.value = value
    }
  }

  onModelValueUpdates(value: any): void {
    if (this.control.value !== value) {
      this.control.setValue(value)
    }
  }

  onModelDisabledUpdates(disabled: boolean): void {
    disabled ? this.control.disable() : this.control.enable()
  }

  onLayoutOrModelChange(): void {
    this.controlLayout = this.layoutService.findByModel(this.model, this.layout) || (this.model.layout as DynamicFormControlLayout)
    this.klass = `${Array.isArray(this.hostClass) ? this.hostClass.join(' ') : ''} ${this.layoutService.getHostClass(this.controlLayout)}`
  }

  onModelChange(): void {
    this.destroyFormControlComponent()
    this.createFormControlComponent()
  }

  onGroupOrModelChange(): void {
    if (this.model) {
      this.unsubscribe()

      if (this.group) {
        this.control = this.group.get(this.model.id) as UntypedFormControl
        this.subscriptions.push(this.control.valueChanges.subscribe((value) => this.onControlValueChanges(value)))
      }

      this.subscriptions.push(this.model.disabledChanges.subscribe((value) => this.onModelDisabledUpdates(value)))

      if (this.model instanceof DynamicFormValueControlModel) {
        const model = this.model as DynamicFormValueControlModel<any>
        this.subscriptions.push(model.valueChanges.subscribe((value) => this.onModelValueUpdates(value)))
      }

      if (this.model.relations.length > 0) {
        this.subscriptions.push(...this.relationService.subscribeRelations(this.model, this.group, this.control))
      }
    }
  }

  onChange($event: Event | DynamicFormControlEvent | any): void {
    if ($event instanceof Event) {
      // native HTML5 change event
      if (this.model.type === DYNAMIC_FORM_CONTROL_TYPE_INPUT) {
        const model = this.model as DynamicInputModel

        if (model.inputType === DYNAMIC_FORM_CONTROL_INPUT_TYPE_FILE) {
          const inputElement: any = $event.target || $event.srcElement
          model.files = inputElement.files as FileList
        }
      }

      this.change.emit(this.createDynamicFormControlEvent($event, DynamicFormControlEventType.Change))
    } else if (isDynamicFormControlEvent($event)) {
      // event bypass
      this.change.emit($event)
    } else {
      // custom library value change event
      this.change.emit(this.createDynamicFormControlEvent($event, DynamicFormControlEventType.Change))
    }
  }

  onBlur($event: FocusEvent | DynamicFormControlEvent | any): void {
    if (isDynamicFormControlEvent($event)) {
      // event bypass
      this.blur.emit($event)
    } else {
      // native HTML 5 or UI library blur event
      this._hasFocus = false
      this.blur.emit(this.createDynamicFormControlEvent($event, DynamicFormControlEventType.Blur))
    }
  }

  onFocus($event: FocusEvent | DynamicFormControlEvent | any): void {
    if (isDynamicFormControlEvent($event)) {
      // event bypass
      this.focus.emit($event)
    } else {
      // native HTML 5 or UI library focus event
      this._hasFocus = true
      this.focus.emit(this.createDynamicFormControlEvent($event, DynamicFormControlEventType.Focus))
    }
  }

  onMetaDataSelected($event: any): void {
    this.metaDataSelectedEvent.emit($event)
  }

  protected createFormControlComponent(): void {
    const componentType = this.componentType

    if (componentType !== null) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType)

      this.componentViewContainerRef.clear()
      this.componentRef = this.componentViewContainerRef.createComponent(componentFactory)

      const component = this.componentRef.instance

      component.formLayout = this.layout
      component.group = this.group
      component.layout = this.controlLayout
      component.model = this.model

      if (component.blur) {
        this.componentSubscriptions.push(component.blur.subscribe(($event: any) => this.onBlur($event)))
      }
      if (component.change) {
        this.componentSubscriptions.push(component.change.subscribe(($event: any) => this.onChange($event)))
      }
      if (component.focus) {
        this.componentSubscriptions.push(component.focus.subscribe(($event: any) => this.onFocus($event)))
      }

      this.registerFormControlComponentRef(this.componentRef)
    }
  }

  protected destroyFormControlComponent(): void {
    if (this.componentRef) {
      this.componentSubscriptions.forEach((subscription) => subscription.unsubscribe())
      this.componentSubscriptions = []

      this.unregisterFormControlComponentRef()
      this.componentRef.destroy()
    }
  }

  protected createDynamicFormControlEvent($event: any, type: string): DynamicFormControlEvent {
    return { $event, control: this.control, group: this.group, model: this.model, type }
  }

  private registerFormControlComponentRef(ref: ComponentRef<DynamicFormControl>): void {
    this.componentService.registerFormControl(this.model, ref)
  }

  private unregisterFormControlComponentRef(): void {
    this.componentService.unregisterFormControl(this.model.id)
  }
}
