<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
  -->
<div class="mx-[10%] s:mx-[4.7%]">
    <div class="flex flex-col s:w-[60%] m:w-[50%]">
        <p-heading theme="dark" align="start" size="xx-large" [tag]="'h1'" class="mt-xxl l:text-balance">{{"signup.maintenance.heading" | translate}}</p-heading>
        <p-text theme="dark" align="start" size="large" class="mt-m text-balance">
            {{"signup.maintenance.description" | translate}}
        </p-text>
        <div>
            <p-link [theme]="'light'" class="mt-l" [icon]="'arrow-right'" [href]="'https://porsche.com'">Go to Porsche.com</p-link>
        </div>
    </div>
</div>





