/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { OneGaManagerData } from './data-handler'
import { OneGaData } from './one-ga-types'

interface ScriptElementClass {
  prototype: HTMLScriptElement

  new (): HTMLScriptElement
}

declare global {
  interface Document {
    oneGaManager: OneGaManagerData
  }

  interface Window {
    trackingLayer: OneGaData[]
    oneGa: any
    pagData: any
  }

  interface Navigator {
    userAgent: string
  }
}

/**
 * this object is used internally to make interaction with the
 * browser more easy to mock in tests
 */
export const browser = {
  addElementToHead(element: HTMLElement): void {
    const head = document.head
    head.appendChild(element)
  },
  getHTMLScriptElement(): ScriptElementClass {
    return HTMLScriptElement
  },
  addScript(src: string) {
    const scriptToAdd = document.createElement('script')
    scriptToAdd.async = true
    scriptToAdd.src = src
    this.addElementToHead(scriptToAdd)
  },
  getWindow(): Window {
    return window
  },
  getDocument(): Document {
    return document
  },
}
