/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'

@Injectable({
  providedIn: 'root',
})
export class FormValidationService {
  /* dynamic-forms doesn't trigger the form validation on submit automatically so we need to do it ourselves.
  However, we just want to mark the enabled controls as touched as otherwise the form is considered invalid if disabled
  controls are being evaluated too. Controls which are being hidden are also disabled for the same reason.
  */
  static triggerFormValidationErrors(formGroup: UntypedFormGroup) {
    Object.entries(formGroup.controls)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, control]) => !control.disabled)
      .forEach(([controlName, control]) => {
        if (controlName.toLowerCase().indexOf('group') !== -1) {
          Object.keys((control as UntypedFormGroup).controls).forEach((groupControl) => {
            const element = document.querySelector(`[name="${groupControl}"]`) as HTMLElement | null

            if (element) {
              element.focus({ preventScroll: true })
              element.blur()
            }
          })
        } else {
          const element = document.querySelector(`[name="${controlName}"]`) as HTMLElement | null

          if (element) {
            element.focus({ preventScroll: true })
            element.blur()
          }
        }
      })

    Object.values(formGroup.controls)
      .filter((ctrl) => !ctrl.disabled)
      .forEach((ctrl) => {
        ctrl.markAllAsTouched()
      })
  }
}
