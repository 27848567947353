<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
  -->

<ng-container [formGroup]="group">
  <div [formGroupName]="model.id" [id]="id" [attr.data-name]="model.name" [ngClass]="getClass('element', 'control')">
    <dynamic-form-control
      *ngFor="let _model of model.group"
      [group]="control"
      [hidden]="_model.hidden"
      [layout]="formLayout"
      [model]="_model"
      [hostClass]="[getClass('element', 'children'), getClass('grid', 'children')]"
      (blur)="onBlur($event)"
      (change)="onChange($event)"
      (focus)="onFocus($event)"
      (metaDataSelectedEvent)="metaDataSelected($event)"
    ></dynamic-form-control>
  </div>
</ng-container>
