/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { DynamicFormValueControlModel, DynamicFormValueControlModelConfig } from './dynamic-form-value-control.model'
import { DynamicFormControlLayout } from './misc/dynamic-form-control-layout.model'
import { isBoolean } from '../utils/core.utils'

export interface DynamicCheckControlModelConfig extends DynamicFormValueControlModelConfig<boolean> {
  labelPosition?: string
}

export abstract class DynamicCheckControlModel extends DynamicFormValueControlModel<boolean> {
  labelPosition: string | null

  protected constructor(config: DynamicCheckControlModelConfig, layout?: DynamicFormControlLayout) {
    super(config, layout)

    this.labelPosition = config.labelPosition || null
    this.checked = isBoolean(this.value) ? this.value : false
  }

  get checked(): boolean {
    return this.value
  }

  set checked(checked: boolean) {
    this.value = checked
  }

  toggle(): void {
    this.checked = !this.checked
  }
}
