/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { NG_VALIDATORS } from '@angular/forms'
import {
  containsLowerCaseValidator,
  containsNumbersValidator,
  containsSpecialCharactersValidator,
  containsUpperCaseValidator,
  emailRfcValidator,
  mobileValidator,
} from './validators'
import { DYNAMIC_VALIDATORS } from '@myprofile-signup-ui/myprofile-dynamic-forms'

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: NG_VALIDATORS,
      useValue: emailRfcValidator,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useValue: mobileValidator,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useValue: containsLowerCaseValidator,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useValue: containsUpperCaseValidator,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useValue: containsNumbersValidator,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useValue: containsSpecialCharactersValidator,
      multi: true,
    },
    {
      provide: DYNAMIC_VALIDATORS,
      useValue: new Map([
        ['emailRfc', emailRfcValidator],
        ['mobile', mobileValidator],
        ['lowercase', containsLowerCaseValidator],
        ['uppercase', containsUpperCaseValidator],
        ['numbers', containsNumbersValidator],
        ['specialchars', containsSpecialCharactersValidator],
      ]),
    },
  ],
})
export class ValidatorsModule {}
