/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core'
import { LoadingService } from './loading.service'

@Injectable({
  providedIn: 'root',
})
export class DecoratorService {
  private static service: LoadingService | undefined = undefined

  public constructor(service: LoadingService) {
    DecoratorService.service = service
  }

  public static getService(): LoadingService {
    if (!DecoratorService.service) throw new Error('DecoratorService not initialized')
    return DecoratorService.service
  }
}
