/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { InjectionToken } from '@angular/core'
import { AsyncValidatorFn, ValidatorFn } from '@angular/forms'

export type Validator = ValidatorFn | AsyncValidatorFn

export type ValidatorFactory = (args: any) => Validator

export type ValidatorsToken = Validator[]

export type ValidatorsMap = Map<string, Validator | ValidatorFactory>

export const DYNAMIC_VALIDATORS = new InjectionToken<ValidatorsMap>('DYNAMIC_VALIDATORS')
