/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2025, Dr. Ing. h.c. F. Porsche AG.
 */

import { DynamicOptionControlModel, DynamicOptionControlModelConfig } from '../dynamic-option-control.model'
import { DynamicFormControlLayout } from '../misc/dynamic-form-control-layout.model'

export const DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP = 'RADIO_GROUP'

export interface DynamicRadioGroupModelConfig<T> extends DynamicOptionControlModelConfig<T> {
  legend?: string
}

export class DynamicRadioGroupModel<T> extends DynamicOptionControlModel<T> {
  legend: string | null
  readonly type: string = DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP

  constructor(config: DynamicRadioGroupModelConfig<T>, layout?: DynamicFormControlLayout) {
    super(config, layout)
    this.legend = config.legend || null
  }

  select(index: number): void {
    this.value = this.get(index).value
  }
}
